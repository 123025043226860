import apiCallHandler from './apiCallHandler'
import api from '@/infra/api'

export const postSignIn = (credentials) => {
  return apiCallHandler(() =>
    api.post(`rh-bff/login${credentials.isKeycloak ? `?isKeycloak=${credentials.isKeycloak}` : ''}`, credentials),
  )
}

export const postRequestResetPassword = (payload, isKeycloak) => {
  return apiCallHandler(() => api.post(`rh-bff/password/forgot?${isKeycloak}`, payload))
}

export const patchResetPassword = async (data, isKeycloak) => {
  return await api.post(`rh-bff/password?${isKeycloak}`, data)
}

export const getRegisterCompanyToken = (site) => {
  return apiCallHandler(() => api.get(`rh-bff/register/${site}`))
}

export const getLoginTheme = (resaleName) => {
  return apiCallHandler(() => api.get(`rh-bff/Session?resale=${resaleName}`))
}
