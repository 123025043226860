import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'

export const useEditEmployee = () => {
  const queryClient = useQueryClient()
  const notify = useNotifications()

  const { mutate: editRow, isLoading } = useMutation({
    mutationFn: async ({ importId, payload }: { importId: string; payload: any }) => {
      const payloadNOError = payload
      payloadNOError.guidImportacao = importId
      delete payloadNOError.errors
      return api.put(`rh-pedidos-bff/importacao/atualizarItem`, payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['brokerImportResults'] })
      notify.push({ content: 'Registro editado', type: EMessageType.Success })
    },
  })

  return {
    editRow,
    isLoading,
  }
}
