import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import api from '@/infra/api'

export const useCreateOrder = () => {
  const navigate = useNavigate()
  const { mutate: createOrder, isLoading } = useMutation({
    mutationKey: ['createOrderTri'],
    mutationFn: ({ importId }: { importId: string }) =>
      api.post(`rh-bff/brokerapi/importacao/gerarpedido`, { IdImportacao: importId }),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/pagamento', {
        state: { title: 'Recarga', isDealer: false, purchaseId: res?.data?.id },
      })
    },
  })

  return {
    createOrder,
    isLoading,
  }
}
