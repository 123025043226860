import { Box } from '@/presentation/components/atoms'
import { Theme } from '@mui/material'

export const NoItems = ({ children }) => {
  return (
    <Box
      sx={(theme: Theme) => ({
        borderRadius: '8px',
        height: '96px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        marginBottom: '16px',
      })}
    >
      {children}
    </Box>
  )
}
