import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
  CircularProgress,
} from '@stationkim/front-ui'
import { BrokerHOC } from '../brokerHOC'
import { IUseBrokerOrderReturn } from './hooks/shared'
import { NewImport } from './components/newImport'
import { RepeatImport } from './components/repeatImport'

enum EOrderMode {
  New = '0',
  Repeat = '1',
}

export const BrokerImport = ({
  integration: { lastOrder, redoLastOrder, layoutOptions, isLoading, uploadImportFile, setOperatorId },
}: {
  integration: IUseBrokerOrderReturn
}) => {
  const [howToOrder, setHowToOrder] = useState<EOrderMode>(EOrderMode.New)
  const isNewOrder = howToOrder === EOrderMode.New

  const form = useForm({ defaultValues: { operatorId: '', files: null } })

  const [operatorId] = form.watch(['operatorId'])
  const operator = layoutOptions.find((option) => option.id === operatorId)

  const navigate = useNavigate()

  const isContinueDisabled =
    isLoading.upload ||
    (isLoading.lastOrder && howToOrder === EOrderMode.Repeat) ||
    (isLoading.options && howToOrder === EOrderMode.New) ||
    isLoading.redoing

  const handleSubmit = (data) => {
    if (!isNewOrder) redoLastOrder.mutate({ orderId: lastOrder.importationId || lastOrder.id })
    else {
      const formData = new FormData()
      formData.append('files', data.files)
      formData.append('idLayout', operator.id)
      uploadImportFile.mutate(formData)
    }
  }

  return (
    <BrokerHOC>
      {isLoading.redoing || isLoading.upload ? (
        <Box
          sx={{ padding: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '320px' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: '32px' }}>
          <Typography sx={{ fontWeight: 500 }}>Selecione o tipo de pedido</Typography>
          <RadioGroup
            value={howToOrder}
            onChange={(e) => setHowToOrder(e.target.value as any)}
            sx={{ margin: ' 0 0 0px 16px' }}
          >
            <FormControlLabel value={EOrderMode.New} control={<Radio />} label='Importar novo pedido' />
            <FormControlLabel
              value={EOrderMode.Repeat}
              control={<Radio />}
              label={'Repetir último pedido'}
              sx={{ marginRight: '8px' }}
              //disabled={isLoading.lastOrder || !lastOrder}
            />
          </RadioGroup>
          <Divider sx={{ margin: '24px 0px' }} />
          {isNewOrder ? (
            <NewImport form={form} isLoading={isLoading} operator={operator} options={layoutOptions} />
          ) : (
            <RepeatImport
              form={form}
              isLoading={isLoading}
              operator={operator}
              options={layoutOptions}
              order={lastOrder}
              setOperatorId={setOperatorId}
            />
          )}
          <Grid container spacing='16px'>
            <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
              <Button variant='outlined' onClick={() => navigate('/recargas')} fullWidth>
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md='auto'>
              <Button
                variant='contained'
                onClick={() => form.handleSubmit(handleSubmit)()}
                type='submit'
                fullWidth
                disabled={isContinueDisabled}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </BrokerHOC>
  )
}
