import Standard from './standard'
import OneOption from './oneOption'
import TwoOptions from './twoOptions'

const ModalV2 = Standard
ModalV2.OneOption = OneOption
ModalV2.TwoOptions = TwoOptions

export default ModalV2

export { TwoOptions }
export { Standard }
export { OneOption }
