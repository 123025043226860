import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { Button, Grid, Icon, PageTitle, Paper, Tab, TabPanel, Tabs, useDocumentTitle } from '@stationkim/front-ui'
import { employeeIdAtom } from '@/main/store'
import { EEmployeeStatus, EFormMode } from '@/main/enums'
import { useEmployee } from './hooks/useEmployee'
import { IEmployeePayload, useEmployeeMutation } from './hooks/useEmployeeMutation'
import { EmployeeForm } from './components/employeeForm/employeeForm'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import ExtractTable from './extract/extract'
import { Benefits } from './components/benefits/benefits'
import { StatusChip } from './components/statusChip/statusChip'
import { ChangeTabConfirmationDialog } from './components/changeTabConfirmationDialog'
import { useQueryClient } from '@tanstack/react-query'

const subtitle = {
  [EFormMode.View]: '',
  [EFormMode.Add]: 'Cadastrar Colaborador',
  [EFormMode.Edit]: ' - Editar',
}

enum ETabs {
  Register = '0',
  Benefits = '1',
  Balance = '2',
}

const employeeFormTabAtom = atomWithHash('tab', ETabs.Register)

export const EmployeeRegister = () => {
  const location = useLocation()
  const { resources } = useAccessProfile({ resource: 'colaborador' })
  const [mode, setMode] = useState<EFormMode | undefined>(location.state?.formMode || EFormMode.View)
  const [lock, setLock] = useState(false)
  const [targetTab, setTargetTab] = useState<ETabs | null>(null)
  const [tab, setTab] = useAtom(employeeFormTabAtom)
  const [employeeId, setEmployeeId] = useAtom(employeeIdAtom)
  const { employee, isLoading: isLoadingEmployee } = useEmployee({ employeeId })
  const documentTitle = useDocumentTitle()
  const pageTitle = (employee?.fullname || '') + subtitle[mode]
  const isViewing = mode === EFormMode.View
  const queryClient = useQueryClient()
  const { createEmployee, editEmployee } = useEmployeeMutation()
  const isLoading = (isLoadingEmployee && mode !== EFormMode.Add) || createEmployee.isLoading || editEmployee.isLoading

  const isEditable = isViewing && tab === ETabs.Register && employee.enabled

  const onEdit = (payload: IEmployeePayload) =>
    editEmployee.mutate(
      { ...payload, employeeId },
      {
        onSuccess: () => {
          setMode(EFormMode.View)
          queryClient.invalidateQueries({ queryKey: ['employee'] })
        },
      },
    )

  const onCreate = (payload: IEmployeePayload) =>
    createEmployee.mutate(payload, {
      onSuccess: (res) => {
        setTab(ETabs.Benefits)
        setMode(EFormMode.View)
        setEmployeeId(res.data)
      },
    })

  useEffect(() => {
    if (employee?.fullname) documentTitle.change({ title: 'Cadastro: ' + employee.fullname })
  }, [employee, documentTitle])

  return (
    <Paper sx={{ overflow: 'hidden', marginBottom: '32px' }}>
      <PageTitle pageTitle={pageTitle} />
      <Grid container>
        <Grid item xs>
          <Tabs
            value={tab}
            onChange={(_e, selectedTab) => {
              if (lock) setTargetTab(selectedTab)
              else setTab(selectedTab)
            }}
          >
            <Tab label='Cadastro' value={ETabs.Register} id='employee_tab_register' />
            <Tab label='Benefícios' value={ETabs.Benefits} id='employee_tab_benefit' disabled={!employeeId} />
            {resources({ item: 'colaborador_dados_extratouso' }) && (
              <Tab label='Extrato' value={ETabs.Balance} id='employee_tab_balance' disabled={!employeeId} />
            )}
          </Tabs>
        </Grid>
        {mode !== EFormMode.Add && (
          <Grid item>
            <StatusChip
              sx={{ margin: '8px 8px 0 8px' }}
              status={employee.status === EEmployeeStatus.Active ? 'active' : 'inactive'}
            />
          </Grid>
        )}
        {isEditable && (
          <Grid item>
            <Button
              variant='contained'
              size='small'
              startIcon={<Icon icon={faPenToSquare} />}
              sx={{ margin: '8px 8px 0 8px' }}
              onClick={() => setMode(EFormMode.Edit)}
              disabled={isLoadingEmployee}
            >
              Editar
            </Button>
          </Grid>
        )}
      </Grid>
      <TabPanel actualTab={tab} value={ETabs.Register}>
        <EmployeeForm
          employee={employee}
          isLoading={isLoading}
          mode={mode}
          setMode={setMode}
          onCreate={onCreate}
          onEdit={onEdit}
          setLock={setLock}
        />
      </TabPanel>
      {mode === EFormMode.Add ? null : (
        <>
          <TabPanel actualTab={tab} value={ETabs.Benefits}>
            <Benefits />
          </TabPanel>
          <TabPanel actualTab={tab} value={ETabs.Balance}>
            <ExtractTable employeeId={employeeId} employeeName={employee?.fullname} visible={tab === ETabs.Balance} />
          </TabPanel>
        </>
      )}
      <ChangeTabConfirmationDialog
        open={targetTab !== null}
        onConfirm={() => {
          setTab(targetTab)
          setLock(false)
          setTargetTab(null)
          setMode(EFormMode.View)
        }}
        onCancel={() => setTargetTab(null)}
      />
    </Paper>
  )
}
