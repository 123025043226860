import api from '@/infra/api'
import { EEmployeeStatus } from '@/main/enums'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

type TAddress = {
  cep: string | null
  state: string | null
  city: string | null
  addressDescript: string | null
  numberAddress: string | null
  complement: string | null
  district: string | null
}

type TIntegration = {
  code: string
  companyCode: number
  departmentCode: number
  resaleId: string
  operatorId: string
  status: number
  errorMsg: string | null
  creationDate: string
  lastEditDate: string | null
  lastIntegrationDate: string | null
}

type TBenefit = {
  employeeId: string
  operatorCardId: string
  status: number
}

type TDailyRate = {
  productId: string
  dailyRateid: string | null
  status: string | null
  description: string | null
  value: number | null
  enabled: boolean
  quantity: number
}

type TProfessionalData = {
  registration: string
  departmentId: string
  workingPlace: string | null
  workingDays: number
  cellphoneNumber: string | null
  secondCellphoneNumber: string | null
  email: string | null
  role: string | null
  sector: string | null
  suspended: boolean
  suspensionReason: number
  suspensionStartDate: string | null
  suspensionEnddate: string | null
  dailyRates: TDailyRate[]
}

interface ApiEmployeeResponse {
  id: string
  fullname: string
  birthDate: string | null
  cpf: string | null
  rg: string | null
  issueDate: string | null
  issuingBody: string | null
  state: string | null
  gender: string | null
  motherName: string | null
  admissionDate: string
  typeOfUse: string
  enabled: boolean
  status: number
  dailyDefaultValue: number
  dailyOrderValue: number
  externalCodeCard: string | null
  tacomEmployeeId: number
  address: TAddress
  integrations: TIntegration[]
  benefits: TBenefit[]
  professionalData: TProfessionalData
}

type TEmployee = {
  cep: string
  state: string
  city: string
  addressDescript: string
  numberAddress: string
  complement: string
  district: string
  registration: string
  departmentId: string
  workingPlace: string
  workingDays: string
  cellphoneNumber: string
  secondCellphoneNumber: string
  email: string
  role: string
  id: string
  fullname: string
  birthDate: string
  cpf: string
  rg: string
  issueDate: string
  issuingBody: string
  gender: string
  motherName: string
  enabled: boolean
  status: EEmployeeStatus
}

export interface IUseEmployeeReturn {
  employee: TEmployee
  isLoading: boolean
}

export const useEmployee = ({ employeeId }: { employeeId: string }): IUseEmployeeReturn => {
  const { data, isLoading } = useQuery<AxiosResponse<ApiEmployeeResponse>>({
    queryKey: ['employee', employeeId],
    queryFn: () => api.get(`/rh-bff/employees/${employeeId}`),
    enabled: Boolean(employeeId),
    cacheTime: 0,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const employee: TEmployee = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return {} as TEmployee
    const employeeData = {
      id: rawData.id,
      cpf: rawData.cpf,
      rg: rawData.rg,
      fullname: rawData.fullname,
      status: String(rawData.status) as EEmployeeStatus,
      enabled: rawData.enabled,
      gender: String(rawData.gender),
      issueDate: rawData.issueDate,
      issuingBody: rawData.issuingBody,
      motherName: rawData.motherName,
      addressDescript: rawData.address.addressDescript,
      birthDate: rawData.birthDate,
      cep: rawData.address.cep,
      city: rawData.address.city,
      complement: rawData.address.complement,
      district: rawData.address.district,
      numberAddress: rawData.address.numberAddress,
      state: rawData.state,
      livingState: rawData.address.state,
      departmentId: rawData.professionalData.departmentId,
      email: rawData.professionalData.email,
      registration: rawData.professionalData.registration,
      role: rawData.professionalData.role,
      secondCellphoneNumber: rawData.professionalData.secondCellphoneNumber,
      cellphoneNumber: rawData.professionalData.cellphoneNumber,
      workingDays: String(rawData.professionalData.workingDays),
      workingPlace: rawData.professionalData.workingPlace,
    }
    for (const key in employeeData) {
      const value = employeeData[key]
      if (!value && value !== 0) employeeData[key] = ''
      else employeeData[key] = String(value)
    }
    return employeeData
  }, [data])

  return {
    employee,
    isLoading,
  }
}
