import { Card } from 'presentation/components/atoms/card'
import { styled } from '@mui/material'

export const StyledCard = styled(Card)(({ children, theme }) => ({
  height: '65px',
  width: '100%',
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
}))

export const StyledCheckbox = styled('span')(({ children, theme }) => ({
  minHeight: 20,
  minWidth: 20,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  background: children ? theme.palette.success.main : 'none',
  border: children ? 'none' : '1px solid ' + theme.palette.grey[500],
}))
