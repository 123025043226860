import { EEmployeeBenefitStatus } from '@/main/enums'
import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const Status = styled(Box, {
  shouldForwardProp: (propName: string) => propName !== 'benefitStatus',
})<{ benefitStatus: EEmployeeBenefitStatus }>(({ theme, benefitStatus }) => {
  const color = {
    [EEmployeeBenefitStatus.Active]: theme.palette.success.dark,
    [EEmployeeBenefitStatus.Inactive]: theme.palette.grey[500],
    [EEmployeeBenefitStatus.Blocked]: theme.palette.info.dark,
    [EEmployeeBenefitStatus.SynchronismError]: theme.palette.error.dark,
    [EEmployeeBenefitStatus.WaitingSynchronism]: theme.palette.warning.dark,
  }
  return {
    paddingLeft: '16px',
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      background: color[benefitStatus],
      borderRadius: '50%',
      height: '8px',
      width: '8px',
    },
  }
})
