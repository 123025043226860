import { Grid, MaskedTextField, Select, TextField, Typography } from '@stationkim/front-ui'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@/presentation/components/atoms'
import { EEmployeeGender } from '@/main/enums'
import { states } from '@/utils/options'
import { EmployeeFormFragmentSkeleton } from './employeeFormFragmentSkeleton'
import { employeeFormRules } from '../utils/employeeFormRules'
import { EmployeeFormFragment } from '../employeeForm'

interface PersonalDataProps extends EmployeeFormFragment {}

const genderOptions = [
  { label: 'Masculino', value: EEmployeeGender.Male },
  { label: 'Feminino', value: EEmployeeGender.Female },
  { label: 'Não Binário', value: EEmployeeGender.NonBinary },
]

export const PersonalData = ({ form, blocked, isLoading, id }: PersonalDataProps) => {
  const { control } = form
  if (isLoading) return <EmployeeFormFragmentSkeleton />
  return (
    <Grid container spacing='16px'>
      <Grid item xs={12}>
        <Typography variant='h2'></Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='cpf'
          rules={employeeFormRules.cpf}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <MaskedTextField
              label='CPF'
              id={`${id}_${name}`}
              mask={{ mask: '000.000.000-00' }}
              placeholder='000.000.000-00'
              blocked={blocked}
              unmask
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='birthDate'
          rules={employeeFormRules.birthDate}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <label style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
              Data de nascimento
              <DatePicker
                id={`${id}_${name}`}
                blocked={blocked}
                inputRef={ref}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
                onChange={onChange}
              />
            </label>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='fullname'
          rules={employeeFormRules.fullname}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Nome completo'
              placeholder='Nome completo'
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={0} lg={3} sx={{ padding: '0 !important' }} />
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='rg'
          rules={employeeFormRules.rg}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <MaskedTextField
              id={`${id}_${name}`}
              label='RG'
              mask={{ mask: '00.000.000-*' }}
              placeholder='00.000.000-0'
              blocked={blocked}
              unmask
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='issueDate'
          rules={employeeFormRules.issueDate}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <label style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
              Data de emissão
              <DatePicker
                id={`${id}_${name}`}
                blocked={blocked}
                onChange={onChange}
                inputRef={ref}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
              />
            </label>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='issuingBody'
          rules={employeeFormRules.issuingBody}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Órgão emissor'
              placeholder='Órgão emissor'
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='state'
          rules={employeeFormRules.state}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <Select
              id={`${id}_${name}`}
              variant='outlined'
              label='Estado'
              options={states}
              blocked={blocked}
              placeholder='Selecione o estado'
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='gender'
          rules={employeeFormRules.gender}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <Select
              id={`${id}_${name}`}
              variant='outlined'
              label='Gênero'
              options={genderOptions}
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='motherName'
          rules={employeeFormRules.motherName}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Nome da Mãe'
              placeholder='Nome da Mãe'
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
