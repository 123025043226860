import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

type TDailyRate = {
  productId: string
  dailyRateid: string
  status: number
  description: string
  value: number
  enabled: boolean
  quantity: number
}

interface ApiResponse {
  availableCards: null
  cardId: string
  cardNumber: string
  dailyRates: TDailyRate[]
  dailyValue: number
  employeeId: string
  operatorCardId: string
  operatorRegistration: string
  status: number //?
}

interface IUseBenefitDataProps {
  employeeId: string
  operatorCardId: string
  cardNumber?: string
}

export const useBenefitData = ({ employeeId, operatorCardId /* , cardNumber */ }: IUseBenefitDataProps) => {
  const isEnabled = Boolean(employeeId) && Boolean(operatorCardId)
  const { data, isLoading } = useQuery<AxiosResponse<ApiResponse>>({
    enabled: isEnabled,
    queryKey: ['employeeBenefitCard', employeeId, operatorCardId /* , cardNumber */],
    queryFn: () =>
      api.get('/rh-bff/Benefit/linkcard/employeecardsfees', {
        params: { employeeId, operatorCardId /* , cardNumber */ },
      }),
    refetchInterval: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
  })

  const card = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return null
    return {
      cardNumber: rawData.cardNumber,
      operatorRegistration: rawData.operatorRegistration,
      cardId: rawData.cardId,
      dailyValue: rawData.dailyValue,
      status: rawData.status,
      dailyRates: rawData.dailyRates.map((rate) => ({
        label: rate.description,
        value: rate.productId,
        rateValue: rate.value,
        enabled: rate.enabled,
        quantity: rate.quantity,
      })),
    }
  }, [data])

  return {
    card,
    isLoading: isLoading && isEnabled,
  }
}
