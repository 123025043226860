import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@stationkim/front-ui'

import { Icon } from './assets/Icon'

import { Actions, MainIcon, Title } from './styles'

interface ConfirmDialogProps {
  onClose?: () => void
  open: boolean
  onConfirm?: () => void
}

export const ConfirmDialog = ({ onClose, open, onConfirm }: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <MainIcon>
        <Icon />
      </MainIcon>
      <Title>Existem colaboradores com erro</Title>
      <DialogContent sx={{ margin: '16px 0 64px 0' }}>
        <Typography variant='body2' sx={{ margin: '16px 0 16px 0' }}>
          Alguns colaboradores na planilha ainda apresentam erro.
          Atualize-os com as informações necessárias ou ignore esta etapa. 
          <b> Caso opte por ignorar, os colaboradores com erro serão descartados e não permanecerão no pedido.</b>
        </Typography>
      </DialogContent>
      <Actions>
        <Button variant='outlined' fullWidth onClick={() => onConfirm()}>
          Continuar sem cadastro
        </Button>
        <Button variant='outlined' fullWidth type='submit' form='newEmployee' onClick={onClose}>
          Cadastrar colaboradores
        </Button>
      </Actions>
    </Dialog>
  )
}
