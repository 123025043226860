import { useContext } from 'react'
import { Controller } from 'react-hook-form'
import { Box, Typography } from '@stationkim/front-ui'
import dayjs from 'dayjs'
import { DatePicker } from '@/presentation/components/atoms'
import { PaymentConfirmationContext } from '@/presentation/pages/paymentConfirmation/paymentConfirmation'

interface DaysForCreditProps {
  unavailableDays: string[]
  initialDate: string
}

const shouldDisableDate = ({
  day,
  unavailableDays,
  minDate,
  maxDate,
}: {
  day: any
  unavailableDays: string[]
  minDate: any
  maxDate: any
}) => {
  if (!day) return true
  const isUnavailable = unavailableDays.some((date) => {
    return day.isSame(date, 'day')
  })
  const isOutOfRange = maxDate.isBefore(day, 'day') || minDate.isAfter(day, 'day')
  const isWeekend = [0, 6].includes(day.day())
  return isUnavailable || isWeekend || isOutOfRange
}

export const DaysForCredit = ({ unavailableDays = [], initialDate }: DaysForCreditProps) => {
  const maxDate = dayjs().add(60, 'day')
  const minDate = dayjs(initialDate)
  const { form } = useContext(PaymentConfirmationContext)
  return (
    <Box
      sx={{
        '& .MuiFormHelperText-root': {
          margin: '4px 0 0 0 !important',
          fontSize: '14px',
        },
      }}
    >
      <Typography variant='subtitle2' sx={{ marginBottom: '8px' }}>
        Data para crédito do pedido
      </Typography>
      <Controller
        control={form.control}
        name='dayForCredit'
        rules={{
          validate: {
            isValidDate: (value) => {
              const isDisabledDate = shouldDisableDate({ day: value, unavailableDays, maxDate, minDate })
              return isDisabledDate ? 'Selecione uma data valida' : true
            },
          },
        }}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
          return (
            <DatePicker
              format='DD/MM/YYYY'
              shouldDisableDate={(day) => shouldDisableDate({ day, unavailableDays, maxDate, minDate })}
              minDate={minDate}
              maxDate={maxDate}
              slotProps={{
                textField: {
                  error: Boolean(error),
                  helperText: error?.message,
                  sx: { width: { xs: '100%', sm: 'auto' } },
                },
              }}
              value={value}
              onChange={onChange}
              inputRef={ref}
            />
          )
        }}
      />
    </Box>
  )
}
