import api from '@/infra/api'
import { EEmployeeBenefitStatus } from '@/main/enums'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type TChangeStatusPayload = {
  benefitId: string
  status: EEmployeeBenefitStatus
}

type TMutationPayload = {
  employeeId: string
  operatorCardId: string
  operatorRegistration: string
  cardNumber: string
  products: { id: string; quantity: number }[]
}

type TEditPayload = {
  benefitId: string
  status: EEmployeeBenefitStatus
} & TMutationPayload

type TCreatePayload = TMutationPayload

export const useBenefitMutation = () => {
  const queryClient = useQueryClient()
  const notifications = useNotifications()
  const createBenefit = useMutation<undefined, unknown, TCreatePayload>({
    mutationKey: ['createBenefit'],
    mutationFn: (payload) => api.post('/rh-bff/Benefit/new', payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['employeeBenefit'] })
      queryClient.invalidateQueries({ queryKey: ['employeeBenefits'] })
      notifications.push({ content: 'Benefício criado com sucesso!', type: EMessageType.Success })
    },
  })

  const editBenefit = useMutation<undefined, unknown, TEditPayload>({
    mutationKey: ['editBenefit'],
    mutationFn: ({ benefitId, ...payload }) => api.put(`/rh-bff/Benefit/edit/${benefitId}`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['employeeBenefit'] })
      queryClient.invalidateQueries({ queryKey: ['employeeBenefits'] })
      notifications.push({ content: 'Benefício editado com sucesso!', type: EMessageType.Success })
    },
  })

  const changeStatus = useMutation<undefined, unknown, TChangeStatusPayload>({
    mutationKey: ['changeBenefitStatus'],
    mutationFn: ({ benefitId, status }) => api.patch(`/rh-bff/Benefit/${benefitId}/activate/${status}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['employeeBenefit'] })
      queryClient.invalidateQueries({ queryKey: ['employeeBenefits'] })
      notifications.push({ content: 'Status alterado com sucesso!', type: EMessageType.Success })
    },
  })

  return {
    createBenefit,
    editBenefit,
    changeStatus,
  }
}
