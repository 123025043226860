import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

type TAddress = {
  cep?: string
  state?: string
  city?: string
  addressDescript?: string
  numberAddress?: string
  complement?: string
  district?: string
}

type TDailyRate = {
  productId?: string
  dailyRateid?: string
  status?: number
  description?: string
  value?: number
  enabled?: boolean
  quantity?: number
}

type TProfessionalData = {
  registration: string
  departmentId?: string
  workingPlace?: string
  workingDays?: number
  cellphoneNumber?: string
  secondCellphoneNumber?: string
  email?: string
  role?: string
  dailyRates?: TDailyRate[]
}

export interface IEmployeePayload {
  fullname: string
  birthDate?: string // ISO 8601 date string
  cpf?: string
  rg?: string
  issueDate?: string // ISO 8601 date string
  issuingBody?: string
  state?: string
  gender?: number
  motherName?: string
  address?: TAddress
  professionalData: TProfessionalData
}

export const useEmployeeMutation = () => {
  const notifications = useNotifications()

  const createEmployee = useMutation<AxiosResponse<string>, unknown, IEmployeePayload>({
    mutationKey: ['createEmployeex'],
    mutationFn: (payload) => api.post('/rh-bff/employees', payload),
    onSuccess: () => {
      notifications.push({
        type: EMessageType.Success,
        content: 'Colaborador cadastrado com sucesso!',
      })
    },
  })

  const editEmployee = useMutation<string, unknown, IEmployeePayload & { employeeId: string }>({
    mutationKey: ['createEmployeex'],
    mutationFn: ({ employeeId, ...payload }) => api.put(`/rh-bff/employees/${employeeId}`, payload),
    onSuccess: () => {
      notifications.push({
        type: EMessageType.Success,
        content: 'Colaborador editado com sucesso!',
      })
    },
  })

  return {
    createEmployee,
    editEmployee,
  }
}
