import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

export const useCart = (params: any = {}) => {
  const { resources } = useAccessProfile({ resource: 'recarga' })

  const isAllowed = resources({ item: 'recarga_carrinho' })

  const { config = {} } = params
  const { data, ...rest } = useQuery<AxiosResponse<any>>({
    queryKey: ['cart', isAllowed],
    queryFn: () => (isAllowed ? api.get(`rh-bff/basket/GetByCompanyId`) : null),
    refetchOnWindowFocus: false,
    ...config,
  })
  return {
    cart: data?.data || {},
    ...rest,
  }
}
