import { Chip, styled } from '@mui/material'
import { StatusChipProps } from './statusChip'

export const StyledChip = styled(Chip, {
  shouldForwardProp: (propName: string) => propName !== 'status',
})<StatusChipProps>(({ theme, status }) => {
  const colorPalette: Record<StatusChipProps['status'], string> = {
    active: theme.palette.success.dark,
    error: theme.palette.error.dark,
    inactive: theme.palette.grey[500],
  }

  return {
    borderRadius: '4px',
    height: '36px',
    paddingLeft: '12px',
    background: colorPalette[status] + '2D',
    position: 'relative',
    fontWeight: 600,
    textTransform: 'capitalize',
    '&::before': {
      content: '" "',
      positions: 'absolute',
      top: 0,
      left: 0,
      height: '10px',
      width: '10px',
      background: `radial-gradient(${colorPalette[status]}, ${colorPalette[status] + '0D'})`,
      borderRadius: '50%',
    },
  }
})
