import React, { useState } from 'react'
import { Table, OrderIcon } from 'presentation/components'
import { Wrapper, StatusDot } from './styles'
import EditUser from '../edit'

const RechargesTable = ({ changeOrder, rechargesInfo, loading, paginator, refresh, setMessage, setSuccess }) => {
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState(null)

  const editUser = (value) => {
    setData(value)
    setEdit(true)
  }

  function generateHeaders() {
    return (
      <>
        <Table.Tr>
          <Table.Th>
            <Wrapper>
              Nome
              <OrderIcon clickUp={() => changeOrder('firstName')} clickDown={() => changeOrder('firstName', false)} />
            </Wrapper>
          </Table.Th>
          <Table.Th>
            <Wrapper>
              E-mail
              <OrderIcon clickUp={() => changeOrder('email')} clickDown={() => changeOrder('email', false)} />
            </Wrapper>
          </Table.Th>
          <Table.Th>
            <Wrapper>
              Status
              <OrderIcon clickUp={() => changeOrder('active')} clickDown={() => changeOrder('active', false)} />
            </Wrapper>
          </Table.Th>
        </Table.Tr>
      </>
    )
  }

  function generateRows(usersInfo) {
    return usersInfo?.length
      ? usersInfo.map((users, index) => (
          <Table.Tr key={index} onClick={() => editUser(users)} style={{ cursor: 'pointer' }}>
            <Table.Td>{users.firstName}</Table.Td>
            <Table.Td>{users.email}</Table.Td>
            <Table.Td>
              <StatusDot status={users.enabled} />
              {users.enabled ? 'Ativo' : 'Inativo'}
            </Table.Td>
          </Table.Tr>
        ))
      : null
  }

  return (
    <>
      <Table loading={loading}>
        <thead>{generateHeaders()}</thead>
        <tbody>{rechargesInfo?.length ? generateRows(rechargesInfo) : null}</tbody>
      </Table>
      {edit && (
        <EditUser
          open={edit}
          close={() => setEdit(false)}
          paginator={paginator}
          data={data}
          refresh={refresh}
          setMessage={setMessage}
          setSuccess={setSuccess}
        />
      )}
    </>
  )
}

export default RechargesTable
