import api from '@/infra/api'
import { downloadString } from '@/main/utils/functions'
import { useMutation } from '@tanstack/react-query'

export const useFetchImportErrors = () => {
  const { mutate: getReport, isLoading } = useMutation({
    mutationKey: ['getBrokerReport'],
    mutationFn: ({ importId }: { importId: string }) => api.get(`rh-pedidos-bff/importacao/relatorio-erro/${importId}`),
    onSuccess: (res) => {
      //todo pass this func to lib
      if (res.data) downloadString(res.data, 'Relatório de erros da importação')
    },
  })

  return {
    getReport,
    isLoading,
  }
}
