import api from '@/infra/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IUseBrokerOrderReturn, LayoutOption } from './shared'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

interface LastOrderAPIReturn {
  pedidos: [
    {
      codigo: number
      dataCriacao: string
      id: number
      idCompra: string
      idCartaoOperadora: string
      idEmpresa: string
      idImportacao: string
      idRevenda: string
      importacaoId: number
      situacao: number
      numeroPedido: number
      totalValor: number
      totalItems: number
    },
  ]
}

interface OperatorsOptionsAPIReturn {
  id: number
  titulo: string
  descricao: string
  localAcao: number
  tecnologiaId: number
  urlModelo: string
  linhaCabecalho: number
  idOperadora: string
}

export const useBrokerOrder = (): IUseBrokerOrderReturn => {
  const [operatorId, setOperatorId] = useState<string>(null)
  const navigate = useNavigate()

  const { data, isLoading: isLoadingLastOrder } = useQuery<AxiosResponse<LastOrderAPIReturn>>({
    queryKey: ['lastBrokerOrder', operatorId],
    queryFn: () => api.get(`rh-bff/brokerapi/pedidos?situacaoPedido=6&idOperadora=${operatorId}`),
    refetchOnWindowFocus: false,
    enabled: Boolean(operatorId),
  })

  const { data: operatorOptions, isLoading: isLoadingOperatorOptions } = useQuery<
    AxiosResponse<OperatorsOptionsAPIReturn[]>
  >({
    queryKey: ['brokerOperatorsOptions'],
    queryFn: () => api.get('rh-bff/brokerapi/layoutConfiguracao'),
    refetchOnWindowFocus: false,
  })

  const { mutate: getImportCopyId, isLoading: getImportCopyIdLoading } = useMutation({
    mutationFn: (orderId: string) => api.post('rh-pedidos-bff/importacao/repetir', { idImportacao: orderId }),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/revisao', {
        state: { importId: res?.data?.idImportacao, isDealer: false },
      })
    },
  })

  const redoLastOrder = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => {
      //remove the promise when unify the integrations
      return new Promise((resolve) => {
        resolve(getImportCopyId(orderId))
      })
    },
  })

  const uploadImportFile = useMutation({
    mutationFn: (payload: FormData) => api.post(`rh-bff/recharge/broker/import-order`, payload),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/revisao', {
        state: { importId: res?.data, isDealer: false },
      })
    },
  })

  const options =
    operatorOptions?.data?.map(
      (operatorOption): LayoutOption => ({
        id: String(operatorOption.id),
        label: operatorOption.titulo,
        modelLink: operatorOption.urlModelo,
        operatorId: operatorOption.idOperadora,
      }),
    ) || []

  const date = data?.data?.pedidos[0]?.dataCriacao
  return {
    lastOrder:
      data?.data?.pedidos?.length > 0
        ? {
            code: data.data.pedidos[0].numeroPedido,
            id: data.data.pedidos[0].codigo,
            importationId: data.data.pedidos[0].idImportacao,
            detailsLink: `/recargas/acompanhamento/#orderId="${data.data.pedidos[0].codigo}"&purchaseId="${data.data.pedidos[0].idCompra}"&isDealer=false`,
            orderDate: date ? new Date(date).toLocaleDateString() : '??/??/????',
            totalValue: data.data.pedidos[0].totalValor,
            itemsInOrder: data.data.pedidos[0].totalItems,
          }
        : null,
    layoutOptions: options,
    redoLastOrder,
    uploadImportFile,
    setOperatorId,
    isLoading: {
      upload: uploadImportFile.isLoading || getImportCopyIdLoading,
      redoing: redoLastOrder.isLoading,
      lastOrder: isLoadingLastOrder && Boolean(operatorId),
      options: isLoadingOperatorOptions,
    },
  }
}
