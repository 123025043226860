import { RegisterOptions } from 'react-hook-form'
import { validateCPF } from 'validations-br'
import { TEmployeeForm } from '../employeeForm'

const defaultRules = {}

const formFieldsNames = [
  'cpf',
  'birthDate',
  'fullname',
  'rg',
  'issueDate',
  'issuingBody',
  'state',
  'gender',
  'motherName',
  'registration',
  'departmentId',
  'role',
  'workingPlace',
  'cellphoneNumber',
  'secondCellphoneNumber',
  'email',
  'workingDays',
  'cep',
]

type FieldRegister = Record<
  keyof TEmployeeForm,
  Omit<RegisterOptions<TEmployeeForm>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
>

const COMPOSITE_NAME_REGEX =
  /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{2,}\s[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{2,}/

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const CHARACTERS_REGEX = /[^0-9|a-z]/gi

const MAX_AGE = 75
const MIN_AGE = 16

const isPhoneComplete = (value: string) => {
  return value?.replace(/\(|\)| |-/g, '').length >= 10 || value?.length === 0 ? true : 'Insira o telefone completo'
}
const isComposite = (value: string) => {
  if (!value) return true
  return value.match(COMPOSITE_NAME_REGEX) || value.length === 0 ? true : 'O campo deve ser composto'
}

const lengthLimit = (length: number) => ({
  value: length,
  message: `Campo limitado a ${length} caracteres`,
})

export const employeeFormRules: FieldRegister = (() => {
  const rules = {} as FieldRegister
  formFieldsNames.forEach((field) => (rules[field] = defaultRules))
  return {
    ...rules,
    cpf: {
      validate: {
        isValid: (value: string) => (validateCPF(value) || value?.length === 0 ? true : 'CPF inválido'),
      },
    },
    birthDate: {
      validate: {
        isValid: (value) => {
          const todayYear = new Date().getFullYear()
          const informedYear = new Date(value).getFullYear()
          if (informedYear < todayYear - MAX_AGE) return `A máxima aceita para cadastro é de ${MAX_AGE} anos`
          if (informedYear > todayYear - MIN_AGE) return `A mínima aceita para cadastro é de ${MIN_AGE} anos`

          return true
        },
      },
    },
    fullname: {
      required: 'Nome é um campo requerido',
      maxLength: lengthLimit(120),
      validate: { isComposite },
    },
    motherName: {
      maxLength: lengthLimit(120),
      validate: {
        isComposite: (value: string) =>
          value?.match(COMPOSITE_NAME_REGEX) || value?.length === 0 ? true : 'O nome da mãe deve ser composto',
      },
    },
    registration: {
      required: 'Matrícula é um campo requerido',
      validate: {
        onlyBasicChars: (value: string) => (!value?.match(CHARACTERS_REGEX) ? true : 'Apenas números e letras'),
      },
    },
    workingDays: {
      min: {
        value: 1,
        message: 'Máximo de 31 dias',
      },
      max: {
        value: 31,
        message: 'Máximo de 31 dias',
      },
    },
    cellphoneNumber: {
      validate: { isPhoneComplete },
    },
    secondCellphoneNumber: {
      validate: { isPhoneComplete },
    },
    email: {
      maxLength: lengthLimit(50),
      validate: {
        isMailValid: (value: string) =>
          value?.match(EMAIL_REGEX) || value?.length === 0 ? true : 'Insira um e-mail válido',
      },
    },
    addressDescript: {
      maxLength: lengthLimit(120),
      validate: { isComposite },
    },
    numberAddress: {
      maxLength: lengthLimit(5),
    },
    complement: {
      maxLength: lengthLimit(50),
    },
    district: {
      maxLength: lengthLimit(60),
    },
    cep: {
      minLength: {
        value: 8,
        message: 'Preencha o CEP completo.',
      },
      validate: {
        isInCepNeed: (value: string, formValue) => {
          const addressFields = [
            formValue.district,
            formValue.addressDescript,
            formValue.numberAddress,
            formValue.complement,
          ]
          return addressFields.some((field) => field?.length > 0) && value?.length === 0
            ? 'Caso digite parte de um endereço o CEP é requerido'
            : true
        },
      },
    },
  }
})()
