import { styled } from '@mui/material'
import { Box, Icon } from '@stationkim/front-ui'

export const RateChipContainer = styled(Box)(() => ({}))

export const RateChipContent = styled(Box)(({ theme }) => ({
  height: '49px',
  marginTop: '12px',
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.primary.light + '1F',
  color: theme.palette.primary.main,
  fontWeight: '500',
  borderRadius: '8px',
  position: 'relative',
  padding: '16px',
  paddingRight: '32px',
  width: '100%',
  '& p': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

export const RemoveButton = styled('button')(({ theme }) => ({
  all: 'unset',
  position: 'absolute',
  right: '16px',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
    transition: 'all .15s linear',
  },
  '&:hover svg': {
    transform: 'scale(.8)',
    color: theme.palette.primary.light,
  },
}))

export const EditIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main + ' !important',
  fontSize: '1rem',
  height: '16px !important',
  width: '16px !important',
  background: theme.palette.primary.light + '2D',
  padding: '4px',
  borderRadius: '4px',
}))
