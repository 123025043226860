import React, { useEffect, useState } from 'react'
import { Button, Input, Panel, Spacing, Spinner, Table, Title } from 'presentation/components'
import { Border, TableName, TextDecoration } from './styles'
import { Controller, useForm } from 'react-hook-form'
import NotificationModal from './notificationModal'
import { requestMigration } from '../requestApi'
import Modal from '../../../modal'

import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { useDispatch } from 'react-redux'
import { getFile } from '@/services/filesServices'
import { FILE_CONTEXT, FILE_KIND, FILE_SUBCONTEXT, TEMP_CONTEXT_ID } from '@/utils/constants'
import useService from 'main/hooks/useService'
import { download } from '@/utils/functionUtils'
import useDebounce from 'main/hooks/useDebouce'
import { useLocation, useNavigate } from 'react-router-dom'

const CompanyInfoResume = ({ operatorInfo, next, operatorsInfo }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const [cancelMigration, setCancelMigration] = useState(false)
  const debounce = useDebounce()
  const {
    state: { token, cnpj, operators },
  } = useLocation()
  const [downloadTermState, downloadTermRequest] = useService(getFile, {
    onCompleted: (response) => {
      if (response.data?.length === 0)
        dispatch(
          pushNotification({
            type: 'error',
            content: response.data.message,
          }),
        )
      else download('data:application/pdf;base64,' + response.data.content, 'Termo de Adesão.pdf')
    },
  })

  const downloadTerm = () => {
    downloadTermRequest(
      `?context=${FILE_CONTEXT.RESALE}&subcontext=${FILE_SUBCONTEXT.TERM}&kind=${FILE_KIND.TERM}&ContextId=${TEMP_CONTEXT_ID}`,
      token,
    )
  }

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
  })

  async function callApi() {
    try {
      navigate('/cadastro/migrar', {
        state: {
          operatorsInfo,
          next: next + 1,
          operators,
          cnpj,
          token,
        },
      })
    } catch (error) {
      dispatch(pushNotification(errorHandler(error.response)))
    }
  }

  function onSubmit(data) {
    if (!data.agree) {
      setModal(true)
      return
    }
    const operator = {
      ...operatorInfo,
      contacts: [
        {
          email: operatorInfo.email ? operatorInfo.email[0] : '',
          phone: operatorInfo.phone,
        },
      ],
    }
    debounce(() => callApi(operator), 500)
  }

  useEffect(() => {
    control._reset()
  }, [control, next])

  return (
    <>
      <Panel>
        <Border>
          <Title.SubTitle>Cadastro</Title.SubTitle>
          <Spacing top='32px' />
          <p>
            Confira os dados de cadastro abaixo e siga para o processo de <strong>migração</strong> para a plataforma da
            Motiva.
            <br />
            Migrando para a Motiva, o cadastro da sua empresa não existirá mais na base da {operatorInfo?.operatorName}.
          </p>
          <Spacing top='24px' />

          <TableName>Dados gerais</TableName>
          <Spacing top='32px' />
          <Table>
            <tbody>
              <Table.Tr>
                <Table.Td>Nome Fantasia</Table.Td>
                <Table.Td>{operatorInfo?.tradeName}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Razão Social</Table.Td>
                <Table.Td>{operatorInfo?.corporateName}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>CNPJ</Table.Td>
                <Table.Td>
                  <Input.Masked
                    displayType='text'
                    format='##.###.###/####-##'
                    value={operatorInfo?.cnpj}
                    style={{
                      border: 'initial',
                      fontSize: 'initial',
                      padding: '0',
                    }}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>E-mail de cadastro</Table.Td>
                <Table.Td>{operatorInfo?.email?.length && operatorInfo.email[0]}</Table.Td>
              </Table.Tr>
            </tbody>
          </Table>
          <Spacing top='50px' />

          <div>
            <form onSubmit={handleSubmit(onSubmit)} id='termsAgreement'>
              <Controller
                control={control}
                name='agree'
                render={({ field: { onChange, value } }) => (
                  <Input.CheckBox onChange={onChange} checked={value} controlled />
                )}
              />
              <span style={{ marginLeft: '16px' }}>
                Li e aceito os termos do &nbsp;
                {downloadTermState.loading ? (
                  <Spinner variant='sm' />
                ) : (
                  <TextDecoration onClick={downloadTerm}>Termo de Adesão.</TextDecoration>
                )}
              </span>
            </form>
          </div>
          <Spacing top='50px' />

          <p>
            Ao confirmar os dados acima, o cadastro desta empresa será migrado para a plataforma da{' '}
            <strong>Motiva.</strong>
            <br /> <strong>Deseja confirmar a migração?</strong>
          </p>
          <Spacing top='20px' />

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='outdivned' width='160px' onClick={() => setCancelMigration(true)}>
              Cancelar
            </Button>
            <Spacing left='24px' />
            <Button width='160px' form='termsAgreement'>
              Confirmar
            </Button>
          </div>
        </Border>
      </Panel>
      <NotificationModal
        open={modal}
        control={() => {
          setModal(false)
        }}
      />
      <Modal
        open={cancelMigration}
        message='Deseja cancelar o processo de migração?'
        confirm={() => navigate('/cadastro')}
        cancel={() => setCancelMigration(false)}
      />
    </>
  )
}

export default CompanyInfoResume
