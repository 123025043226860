import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '@mui/material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@stationkim/front-ui'

interface ChangeTabConfirmationDialogProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ChangeTabConfirmationDialog = ({ open, onCancel, onConfirm }: ChangeTabConfirmationDialogProps) => {
  return (
    <Dialog open={open}>
      <Icon
        icon={faTriangleExclamation}
        sx={(theme: Theme) => ({
          background: theme.palette.warning.main + '2D',
          color: theme.palette.warning.main,
          height: '64px',
          width: '64px',
          padding: '16px',
          borderRadius: '50%',
          margin: 'auto',
          marginBottom: '16px',
        })}
      />
      <DialogTitle
        color='warning'
        sx={(theme: Theme) => ({
          margin: 'auto',
          color: theme.palette.warning.main,
        })}
      >
        Atenção!
      </DialogTitle>
      <DialogContent sx={{ margin: '16px 0 32px 0' }}>
        Você possui alterações não salvas. Deseja avançar mesmo assim ou voltar para salvar as alterações?
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '16px' }}>
        <Button variant='outlined' sx={{ filter: 'grayscale(100)' }} fullWidth onClick={onCancel}>
          Voltar
        </Button>
        <Button variant='contained' color='warning' fullWidth onClick={onConfirm}>
          Avançar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
