import React, { useEffect, useState } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Input,
  Button,
  ErrorMessage,
  ErrorWrapper,
  Title,
  Spacing,
  Icon,
  TextDecoration,
} from 'presentation/components'
import { AddContact, Fieldset, Label, SendFormConfirmation, Trash, Row } from './style'
import { useDispatch } from 'react-redux'
import schema from '../schema/contacts'
import Modal from '../../modal'
import { companyContactsClear } from '@/store/modules/empresa/actions'
import { FORM_MODE } from '@/utils/constants'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

const CONTACS_LIMIT = 3

const Contacts = ({
  changeForm,
  controlNavigation,
  mode,
  nextTab,
  prevTab,
  saveOnStore,
  companyData,
  sendToApi,
  loading,
}) => {
  const readOnly = mode === FORM_MODE.VIEW ? true : false
  const [cancelModal, setCancelModal] = useState(false)
  const [removeContact, setRemoveContact] = useState(null)
  const [finishForm, setFinishForm] = useState(false)
  const [contactTypes, setContactTypes] = useState([])
  const dispatch = useDispatch()
  const {
    state: { token },
  } = useLocation()

  let defaultValues = {}

  function putMainAsFirst() {
    const mainIndex = companyData?.contacts?.findIndex((contact) => contact.useThisContactAsMain === true)
    if (mainIndex > 0) {
      const aux = companyData?.contacts[0]
      companyData.contacts[0] = companyData.contacts[mainIndex]
      companyData.contacts[mainIndex] = aux
    }
  }

  if (companyData != null) {
    companyData?.contacts && putMainAsFirst()
    defaultValues = { contacts: (companyData?.contacts?.length > 0 && companyData.contacts) || [{ fullName: '' }] }
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
    context: { register: !mode ? true : false },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  })

  useEffect(() => {
    const canPass = isValid
    controlNavigation(canPass)
  }, [isValid, controlNavigation])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/contact/contact-types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setContactTypes(response?.data?.data)
    })()
  }, [])

  const onSubmit = (data) => {
    saveOnStore(data)
    setFinishForm(true)
  }

  changeForm(() => {
    handleSubmit(saveOnStore)(getValues())
  })

  function generateContactFields() {
    return fields.map((_, index) => {
      const hasError = errors.contacts
        ? {
            cpf: errors.contacts[index]?.cpf,
            phone: errors.contacts[index]?.phone,
            fullName: errors.contacts[index]?.fullName,
            email: errors.contacts[index]?.email,
            extension: errors.contacts[index]?.extension,
            type: errors.contacts[index]?.type,
          }
        : null

      return (
        <React.Fragment key={_.id}>
          <Row gutterWidth={40}>
            <Col sm={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title.SubTitle>{`Contato ${index + 1}`}</Title.SubTitle>
                <Spacing left='16px' />
                {index !== 0 && (
                  <Trash>
                    <Icon
                      name='trash'
                      onClick={() => {
                        setRemoveContact(() => () => {
                          remove(index)
                          setRemoveContact(null)
                        })
                      }}
                    />
                  </Trash>
                )}
              </div>
            </Col>
          </Row>
          <Row gutterWidth={40}>
            <Col sm={5}>
              <ErrorWrapper error={hasError?.fullName}>
                <Label>Nome Completo *</Label>
                <Input placeholder='Nome Completo' maxLength='130' {...register(`contacts.${index}.fullName`)} />
                {hasError?.fullName && <ErrorMessage>{errors.contacts[index].fullName.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.cpf}>
                <Label>CPF *</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.cpf`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      placeholder='000.000.000-00'
                      mask={'*'}
                      format='###.###.###-##'
                      value={value}
                      getInputRef={ref}
                      disabled={mode === FORM_MODE.EDIT}
                      onChange={onChange}
                    />
                  )}
                />
                {hasError?.cpf && <ErrorMessage>{errors.contacts[index].cpf.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col>
              <ErrorWrapper error={hasError?.type}>
                <Label>Tipo de Contato *</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.type`}
                  render={({ field: { onChange, value, ref } }) => {
                    const newValue = contactTypes.find((type) => type?.code === value)

                    return (
                      <Input.Select
                        placeholder='Selecione'
                        value={newValue}
                        inputRef={ref}
                        onChange={(option) => {
                          onChange(option.code)
                        }}
                        options={contactTypes}
                        getOptionValue={(option) => option.code}
                        getOptionLabel={(option) => option.description}
                        isLoading={contactTypes.length === 0}
                        isDisabled={contactTypes.length === 0}
                      />
                    )
                  }}
                />
                {hasError?.type && <ErrorMessage>{errors.contacts[index].type?.code?.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
          </Row>
          <Row gutterWidth={40}>
            <Col sm={5}>
              <ErrorWrapper error={hasError?.email}>
                <Label>E-mail *</Label>
                <Input
                  maxLength='256'
                  placeholder='colaborador@empresa.com.br'
                  {...register(`contacts.${index}.email`)}
                />
                {hasError?.email && <ErrorMessage>{errors.contacts[index].email.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.phone}>
                <Label>Telefone *</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.phone`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      format='(##) #####-####'
                      placeholder='(00) 00000-0000'
                      value={value}
                      getInputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
                {hasError?.phone && <ErrorMessage>{errors.contacts[index].phone.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.extention}>
                <Label>Ramal</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.extension`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      placeholder='00000'
                      maxLength='5'
                      value={value}
                      getInputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
                {hasError?.extention && <ErrorMessage>{errors.contacts[index].extention.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
          </Row>
        </React.Fragment>
      )
    })
  }

  function confirmCancel() {
    setCancelModal(false)
    dispatch(companyContactsClear())
    prevTab()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='contactInformation'>
        <Fieldset disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            {generateContactFields()}
            <Row gutterWidth={40}>
              <Col sm={12}>
                {fields.length < CONTACS_LIMIT && (
                  <AddContact onClick={() => append({ fullName: '' })}>Adicionar contato</AddContact>
                )}
              </Col>
            </Row>
          </Container>
        </Fieldset>
      </form>
      <Row justify='end' gutterWidth={24} style={{ marginTop: '8vh' }}>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={prevTab}>
              Voltar
            </Button>
          ) : (
            <Button variant='outlined' onClick={() => setCancelModal(true)}>
              Cancelar
            </Button>
          )}
        </Col>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab}>
              Próximo
            </Button>
          ) : (
            <Button type='submit' form='contactInformation'>
              Salvar
            </Button>
          )}
        </Col>
      </Row>
      {!!removeContact && (
        <Modal
          open={!!removeContact}
          message='Deseja mesmo apagar este contato?'
          confirm={() => {
            removeContact()
          }}
          cancel={() => {
            setRemoveContact(null)
          }}
        />
      )}
      {cancelModal && (
        <Modal
          message='Atenção! Os dados preenchidos serão perdidos. Deseja sair da página?'
          open={cancelModal}
          confirm={confirmCancel}
          cancel={() => setCancelModal(false)}
        />
      )}
      {finishForm && (
        <Modal
          open={finishForm}
          confirm={() => {
            sendToApi(true)
          }}
          loading={loading}
          cancel={() => setFinishForm(false)}
        >
          <SendFormConfirmation>
            <p>
              <TextDecoration>Atenção!</TextDecoration>{' '}
            </p>
            <p>
              Após passar para a proxima etapa <br />
              <TextDecoration>não será possível alterar as informações</TextDecoration>.
            </p>
            <p>Deseja continuar?</p>
          </SendFormConfirmation>
        </Modal>
      )}
    </>
  )
}
export default Contacts
