import { Grid, Skeleton } from '@stationkim/front-ui'

export const EmployeeFormFragmentSkeleton = () => {
  return (
    <Grid container spacing='16px'>
      {Array(9).fill(
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Skeleton variant='rounded' height='47px' width='100%' />
        </Grid>,
      )}
    </Grid>
  )
}
