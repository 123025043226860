import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'
import axios from 'axios'

export const getBenefits = (site, token) => {
  return apiCallHandler(() =>
    axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/register/operatorCard?site=${site}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}

export const checkIfOperatorHasRegister = ({ cnpj, operatorsCard, token }) =>
  apiCallHandler(() =>
    axios.post(
      `${process.env.REACT_APP_API_HOST}/rh-bff/register/checkIfOperatorHasRegister`,
      {
        cnpj,
        operatorsCard,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  )
