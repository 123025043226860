import { useEffect, useState } from 'react'
import { decode } from 'jsonwebtoken'
import { useLocation, useNavigate } from 'react-router'

import { AccountPersistentStore, EMessageType } from '../store'
import { useAtomWithStorageObserver } from './useAtomWithStorageObserver'
import { useNotifications } from './useNotifications'

interface IUseAccessProfile {
  menu?: {
    pathname: string
    icon: JSX.Element
    displayName: string
    role: string
  }[]
  resource?: string
}

export const useAccessProfile = (props?: IUseAccessProfile) => {
  const menu = props?.menu
  const resource = props?.resource

  const { push } = useNotifications()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [menus, setMenus] = useState([] as IUseAccessProfile['menu'])

  const [account] = useAtomWithStorageObserver({
    atomWithStorage: AccountPersistentStore,
    storageName: 'account',
  })

  const token = account?.auth?.accessToken || ''
  const profiles = decode(token)

  const routes = [...(profiles?.['realm_access']?.roles || [])].filter((role) => role?.includes?.('_menu'))
  const roles = [...(profiles?.['realm_access']?.roles || [])].filter(
    (role) => !role?.includes?.('_menu') && !role?.includes?.('menu_') && role.startsWith(resource),
  )

  const decodedTokenToMenu = () => {
    const newMenu = menu?.filter((item) => {
      return routes?.find((route) => route === item?.role)
    })

    setMenus(newMenu)
  }

  const checkRoute = () => {
    const isAllowed = routes?.map((role) => `/${role.replace('_menu', '')}`)?.some((menu) => pathname?.includes(menu))

    const publicRoutes = ['/', '/cadastro', '/cadastro/', '/cadastro/migrar', '/cadastro/migrar/']

    if (isAllowed || publicRoutes.includes(pathname)) {
      return
    }

    push({ content: 'O usuário não tem permissão para acessar a funcionalidade', type: EMessageType.Warning })

    return navigate('/')
  }

  const checkSubRoute = ({ resource }: { resource: string }) => {
    if (!resources({ item: resource })) {
      push({ content: 'O usuário não tem permissão para acessar a funcionalidade', type: EMessageType.Warning })

      navigate('/')
    }
  }

  const resources = ({ item }: { item: string }) => {
    return profiles?.['realm_access']?.roles
      ?.filter((role) => role.includes(`${resource}_`))
      ?.find((resourceName) => resourceName === item)
  }

  useEffect(() => {
    decodedTokenToMenu()
  }, [])

  useEffect(() => {
    checkRoute()
  }, [menus, pathname])

  return {
    menus,
    resources,
    roles,
    checkSubRoute,
  }
}
