import React, { useContext } from 'react'
import _ from 'lodash'
import { IOrderTrackingContext, OrderTrackingContext } from '../../orderTracking'
import { Box, Card, Divider } from 'presentation/components/atoms'
import { Search, Summary, TableFooterPagination } from 'presentation/components/molecules'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'
import { OrderItem } from './components/orderItem/orderItem'
import { OrderItemsSkeleton } from './components/orderItemsSkeleton/orderItemsSkeleton'
import { NoItems } from './components/noItems/noItems'
import { OrderTitle } from './components/orderTitle/orderTitle'
import { ORDER_STATUS } from 'main/utils/constants'
import { IOrderItem, IOrderItems } from '../../hooks/utils/interfaces/iOrderItems'

export const OrderItems = () => {
  const {
    orderDate,
    isRecharge,
    orderStatus,
    items = {} as IOrderTrackingContext['items'],
  } = useContext(OrderTrackingContext)
  const { orderItems = {} as IOrderItems, isLoading, filter, goToPage, setPageSize, updateFilter } = items
  const totalItems = orderItems?.totalItems || 0
  const renderOrderItems = () => {
    if (!orderItems || !orderItems.data || orderItems.data.length === 0)
      return <NoItems>{filter?.filterValue !== '' ? 'Não há resultado para a pesquisa' : 'Sem registro'}</NoItems>
    return orderItems.data.map((orderItem: IOrderItem) => {
      return (
        <React.Fragment key={_.uniqueId()}>
          <OrderItem orderItem={orderItem} />
        </React.Fragment>
      )
    })
  }

  const generateItemsString = () => {
    if (isRecharge) return totalItems > 1 ? 'Colaboradores' : 'Colaborador'
    else return totalItems > 1 ? 'Itens' : 'Item'
  }
  return (
    <Card sx={{ padding: '16px', marginTop: '32px' }}>
      <Summary
        title={<OrderTitle isCanceled={[ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING].includes(orderStatus)} />}
        subtitle={`${orderDate} ⚬ ${totalItems} ${generateItemsString()} `}
        icon={isRecharge ? <RechargeIcon /> : <CardIcon />}
        noMargin
      >
        <Box sx={{ overflow: 'hidden' }}>
          <Divider sx={{ marginBottom: '16px' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Search
              onSearch={(value: string) => {
                goToPage(1)
                updateFilter({
                  filterValue: String(value).replaceAll(/[./-]/g, ''),
                  filterProps: 'employeeName,employeeRegistration,operatorRegistration,cardNumber',
                })
              }}
              placeholder='Pesquisar na tabela abaixo...'
              sx={{ maxWidth: '342px', filter: 'grayscale(100%)' }}
            />
          </Box>

          <Box sx={{ overflow: 'auto' }}>
            <Box sx={{ width: '100%' }}>{isLoading ? <OrderItemsSkeleton /> : renderOrderItems()}</Box>
          </Box>
          <TableFooterPagination
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
            onPageChange={(e, value) => goToPage(value)}
            page={orderItems.page}
            rowsPerPage={orderItems.pageSize}
            totalItems={orderItems.totalItems}
            totalPages={orderItems.totalPages}
            displayText='N° de linhas'
            counterVariant='interval'
            hideSinglePage={true}
            rowsPerPageOptions={[10, 50, 200, 500]}
            hideDivider
          />
        </Box>
      </Summary>
    </Card>
  )
}
