import { createContext, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useAtomValue } from 'jotai'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Tooltip,
} from '@stationkim/front-ui'
import { EBenefitType, EEmployeeBenefitStatus, EFormMode } from '@/main/enums'
import { employeeIdAtom } from '@/main/store'
import { CardForm } from './components/cardForm'
import { CardRatesForm } from './components/cardRatesForm'
import { useBenefitMutation } from '../hooks/useBenefitMutation'
import { useBenefitData } from '../hooks/useBenefitData'
import { IUseAvailableBenefitsReturn, useAvailableBenefits } from '../../../hooks/useAvailableBenefits'
import { useEmployeeBenefit } from '../hooks/useEmployeeBenefit'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { EditIcon } from './components/styles'
import { StatusChip } from '../../statusChip/statusChip'
import { Switch } from '@mui/material'

type TProduct = {
  id: string
  label: string
  quantity: number
  value: number
  total: number
}
export interface EBindCardForm {
  benefitType: EBenefitType
  operator: string
  operatorRegistration: string
  operatorCard: string
  cardNumber: string
  dailyValueTotal: string
  status: EEmployeeBenefitStatus
  productsIds: string[]
  products: TProduct[]
  dailyValue: string
  benefitId: string
}

const baseId = 'employeeBenefitDialog'
export const defaultValues = {
  benefitType: -1,
  cardNumber: '',
  dailyValue: '',
  dailyValueTotal: '',
  operator: '',
  operatorCard: '',
  operatorRegistration: '',
  productsIds: [],
  status: EEmployeeBenefitStatus.Active,
}

export type TBenefitBind = {
  benefitId: string
  benefitType: EBenefitType
  cardId: string | null
  cardName: string
  cardNumber: string | null
  dailyRate: number
  status: EEmployeeBenefitStatus
}

interface BenefitBindDialogProps {
  initialMode?: EFormMode
  onClose: () => void
  existentBenefitsBinds?: TBenefitBind[]
  initialBenefitData: TBenefitBind
}

interface IBenefitBindDialogContext {
  form: UseFormReturn<EBindCardForm>
  mode: EFormMode
  setMode: React.Dispatch<React.SetStateAction<EFormMode>>
  onSubmit: (data: EBindCardForm) => void
  benefits: IUseAvailableBenefitsReturn['benefits']
  card: ReturnType<typeof useBenefitData>['card']
  isLoadingBenefitData: boolean
  isLoadingCardData: boolean
  setCardName: React.Dispatch<React.SetStateAction<string>>
}

export const BenefitBindDialogContext = createContext<IBenefitBindDialogContext>({} as IBenefitBindDialogContext)

export const BenefitBindDialog = ({
  initialMode = EFormMode.Add,
  onClose,
  existentBenefitsBinds,
  initialBenefitData,
}: BenefitBindDialogProps) => {
  const employeeId = useAtomValue(employeeIdAtom)
  const [mode, setMode] = useState<EFormMode>(initialMode)
  const [step, setStep] = useState<1 | 2>(1)
  const [cardName, setCardName] = useState<string>('Cartão')
  const form = useForm<EBindCardForm>({ defaultValues, shouldUnregister: false })
  const [operatorCard, cardNumber] = form.watch(['operatorCard', 'cardNumber'])
  const { editBenefit, createBenefit } = useBenefitMutation()
  const { benefits, isLoading: isLoadingBenefitData } = useAvailableBenefits()
  const { card, isLoading: isLoadingCardData } = useBenefitData({
    employeeId,
    operatorCardId: operatorCard,
    cardNumber,
  })
  const { benefit, isLoading: isLoadingBenefit } = useEmployeeBenefit({
    benefitId: initialBenefitData?.benefitId,
    enabled: mode !== EFormMode.Add,
  })
  const isLoading = editBenefit.isLoading || createBenefit.isLoading || isLoadingBenefit
  const title = useMemo(
    () => ({
      [EFormMode.Edit]: 'Editar cartão',
      [EFormMode.Add]: 'Vincular Benefício',
      [EFormMode.View]: cardName,
    }),
    [cardName],
  )
  const isViewing = mode === EFormMode.View

  const onSubmit: SubmitHandler<EBindCardForm> = (data) => {
    if (step === 1) {
      setStep(2)
      const products: TProduct[] = data.productsIds.map((productId) => {
        const productInfo = card.dailyRates?.find((rate) => rate.value === productId)
        const productQuantity = productInfo.quantity > 0 ? productInfo.quantity : 2
        return {
          id: productInfo.value,
          quantity: productQuantity,
          label: productInfo.label,
          value: productInfo.rateValue,
          total: productQuantity * productInfo.rateValue,
        }
      })
      form.setValue('products', products)
    } else if (step === 2) {
      console.log(data)
      const payload = {
        cardNumber: data.cardNumber,
        employeeId: employeeId,
        operatorCardId: data.operatorCard,
        operatorRegistration: data.operatorRegistration,
        products: data.products.map((product) => ({
          id: product.id,
          quantity: Number(product.quantity),
        })),
      }
      if (mode === EFormMode.Edit) {
        editBenefit.mutate(
          {
            ...payload,
            benefitId: data.benefitId,
            status: data.status,
          },
          { onSuccess: onClose },
        )
      } else if (mode === EFormMode.Add) createBenefit.mutate(payload, { onSuccess: onClose })
    }
  }

  const onCancel = () => {
    if (step === 1) onClose()
    else setStep(1)
  }

  useEffect(() => {
    if (initialBenefitData && mode !== EFormMode.Add && benefit) {
      form.setValue('benefitId', initialBenefitData.benefitId)
      form.setValue('benefitType', initialBenefitData.benefitType)
      form.setValue('status', initialBenefitData.status)
      form.setValue('dailyValue', String(initialBenefitData.dailyRate).replace('.', ','))
      form.setValue('operator', benefit.operatorId)
      form.setValue('operatorCard', String(benefit.operatorCardId))
    }
  }, [initialBenefitData, form, mode, benefit])

  const contextValue: IBenefitBindDialogContext = {
    form,
    mode,
    setMode,
    onSubmit,
    benefits,
    card,
    isLoadingBenefitData,
    isLoadingCardData,
    setCardName,
  }
  return (
    <BenefitBindDialogContext.Provider value={contextValue}>
      <Dialog open maxWidth='md'>
        <DialogTitle>
          {step === 2 ? 'Configurar Tarifas' : title[mode]}
          {isViewing && (
            <>
              <Tooltip title='Editar' placement='top'>
                <button style={{ all: 'unset', margin: '0 auto 0 16px' }} onClick={() => setMode(EFormMode.Edit)}>
                  <EditIcon icon={faPen} />
                </button>
              </Tooltip>
              <StatusChip status={benefit?.status === EEmployeeBenefitStatus.Active ? 'active' : 'inactive'} />
            </>
          )}
          {mode === EFormMode.Edit && (
            <Controller
              control={form.control}
              name='status'
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value === EEmployeeBenefitStatus.Active}
                  onChange={(e) => {
                    const newValue = e.target.checked ? EEmployeeBenefitStatus.Active : EEmployeeBenefitStatus.Inactive
                    onChange(newValue)
                  }}
                  color='success'
                />
              )}
            />
          )}
        </DialogTitle>
        <DialogContent sx={{ margin: '32px 0' }}>
          {isLoading ? (
            <Grid container spacing='16px'>
              {Array(6).fill(
                <Grid item xs={12} sm={6}>
                  <Skeleton variant='rounded' height='46px' width='100%' sx={{ minWidth: '100px' }} />
                </Grid>,
              )}
            </Grid>
          ) : (
            <>
              <CardForm id={baseId} visible={step === 1} existentBenefitsBinds={existentBenefitsBinds} />
              <CardRatesForm form={form} id={baseId} visible={step === 2} />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', gap: '8px' }}>
          <Button
            fullWidth
            variant='outlined'
            sx={{ filter: 'grayscale(100)' }}
            onClick={onCancel}
            disabled={isLoading || isLoadingBenefitData || isLoadingCardData}
          >
            {step === 1 && mode !== EFormMode.View ? 'Cancelar' : 'Voltar'}
          </Button>
          {mode !== EFormMode.View && (
            <Button
              variant='contained'
              fullWidth
              type='submit'
              form='employeeBenefitBindForm'
              disabled={isLoading || isLoadingBenefitData || isLoadingCardData}
            >
              Salvar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </BenefitBindDialogContext.Provider>
  )
}
