import api from '@/infra/api'
import { Option } from '@/main/types'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

type TDepartmentIntegration = {
  companyIntegrationId: string
  departmentId: string
  code: number
  status: number
  errorMsg: string | null
  creationDate: string
  lastEditDate: string | null
  lastIntegrationDate: string | null
  id: string
  active: boolean
}

interface ApiDepartment {
  id: string
  companyId: string
  name: string
  employeesCount: number
  situation: number
  integrations: TDepartmentIntegration[]
  isNoDepartment: boolean
}

interface IUseWorkingPlacesOptionsReturn {
  isLoading: boolean
  workingPlacesOptions: Option[]
}

export const useWorkingPlacesOptions = ({ companyId }: { companyId: string }): IUseWorkingPlacesOptionsReturn => {
  const { data, isLoading } = useQuery<AxiosResponse<ApiDepartment[]>>({
    queryKey: ['employeeWorkingPlacesOptions', companyId],
    queryFn: () => api.get(`/companies-service/${companyId}/Addresses`),
    enabled: Boolean(companyId),
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const workingPlacesOptions: IUseWorkingPlacesOptionsReturn['workingPlacesOptions'] = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return []

    return rawData.map((department) => ({
      label: department.name,
      value: department.id,
    }))
  }, [data])

  return {
    workingPlacesOptions,
    isLoading,
  }
}
