import * as yup from 'yup'

const addressRegex =
  /^[0-9A-Za-zªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{1,}\s[0-9A-Za-zªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{1,}/

const addresGridValidation = yup.array().of(
  yup.object().shape({
    laneName: yup
      .string()
      .max(120)
      .matches(addressRegex, {
        message: 'O endereço deve ser composto',
        excludeEmptyString: true,
      })
      .required('Endereço é um campo obrigatório'),
    number: yup.string().max(5),
    complement: yup.string().max(30),
    neighborhood: yup.string().max(50).required('Bairro é um campo obrigatório'),
    name: yup.string().max(30).required('Nome do endereço é um campo obrigatório'),
    cityIbgeCode: yup.string().required(),
    cep: yup
      .string()
      .test('is-complete-cep', 'Preencha o CEP completo.', (value) => {
        if (value?.match(/-/g)) return value?.length === 9
        if (!value?.match(/-/g)) return value?.length === 8
      })
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .required('CEP é um campo obrigatório'),
    type: yup.number().required('Tipo de endereço é um campo obrigatório'),
  }),
)

const schema = yup.object().shape({
  abortEarly: false,
  addresses: addresGridValidation,
  deliveryAddresses: addresGridValidation,
})

export default schema
