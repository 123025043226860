import api from '@/infra/api'
import { ECardUsage } from '@/main/enums'
import { employeeIdAtom } from '@/main/store'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

type TBenefitType = {
  benefitType: number
  name: string
  operators: TOperator[]
}

type TOperator = {
  id: string
  tradeName: string
  corporateName: string
  operatorCards: TOperatorCard[]
}

type TOperatorCard = {
  id: string
  operatorCardId: string
  benefitType: number
  name: string
  processType: number
  assigned: boolean
  cards: TCard[]
}

type TCard = {
  id: string
  operatorCardId: string
  number: string
  status: number
}

type TDepartment = {
  id: string
  name: string
  companyId: string
  situation: number
}

type TStatus = {
  id: number
  name: string
}

type ApiResponse = {
  benefitsTypes: TBenefitType[]
  departments: TDepartment[]
  status: TStatus[]
}

type TOption = {
  label: string
  value: string
}

export type TOperatorCardOption = {
  cardUsage: ECardUsage
  availableCards: TOption[]
} & TOption

export type TOperatorOption = {
  operatorCards: TOperatorCardOption[]
} & TOption

type TBenefitOption = {
  label: string
  value: number
  operators: TOperatorOption[]
}

export interface IUseAvailableBenefitsReturn {
  isLoading: boolean
  benefits: TBenefitOption[]
}

export const useAvailableBenefits = (): IUseAvailableBenefitsReturn => {
  const employeeId = useAtomValue(employeeIdAtom)

  const { data, isLoading } = useQuery<AxiosResponse<ApiResponse>>({
    queryKey: ['employeeBenefits', employeeId],
    queryFn: () => api.get(`rh-bff/Benefit/linkcard/operatorcards?employeeId=${employeeId}`),
    refetchInterval: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
  })

  const benefits = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return []
    const benefitsWithOperators = rawData.benefitsTypes.filter((benefit) => benefit.operators.length > 0)
    return benefitsWithOperators.map((benefit) => ({
      label: benefit.name,
      value: benefit.benefitType,
      operators: benefit.operators.map((operator) => ({
        label: operator.tradeName,
        value: operator.id,
        operatorCards: operator.operatorCards.map((card) => ({
          value: card.operatorCardId,
          label: card.name,
          cardUsage: card.processType,
          availableCards: card.cards.map((card) => ({
            label: card.number,
            value: card.id,
          })),
        })),
      })),
    }))
  }, [data])

  return {
    benefits,
    isLoading,
  }
}
