import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'
import { IUseBrokerOrderReturn, LastOrder, LayoutOption } from './shared'
import { useNavigate } from 'react-router-dom'

const optionsMock: LayoutOption[] = [
  {
    id: '123',
    label: 'Ótimo',
    modelLink: process.env.REACT_APP_IMPORT_BROKER_RECHARGE_ORDER_FILE_MODEL_URL_OLD,
    operatorId: '',
  },
]

export const useBrokerOrderOld = (): IUseBrokerOrderReturn => {
  const navigate = useNavigate()

  const { data, isLoading: isLoadingLastOrder } = useQuery<AxiosResponse<{ lastOrder: LastOrder }>>({
    queryKey: ['lastBrokerOrder'],
    queryFn: () => api.get(`rh-bff/recharge/broker`),
    refetchOnWindowFocus: false,
  })

  const redoLastOrder = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => api.post(`rh-bff/recharge/broker/${orderId}/copy`),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/dados', {
        state: { orderId: res?.data },
      })
    },
  })

  const uploadImportFile = useMutation({
    mutationFn: (payload: FormData) => api.post(`rh-bff/recharge/broker/import`, payload),
    onSuccess: (res) => {
      const hasError = res?.data?.base64
      if (hasError)
        navigate('/recargas/pedido_recarga_broker/resultados_importacao', {
          state: { orderId: res?.data?.id, error: res?.data },
        })
      else
        navigate('/recargas/pedido_recarga_broker/dados', {
          state: { orderId: res?.data?.id },
        })
    },
  })

  return {
    lastOrder: {
      code: data?.data?.lastOrder?.code,
      id: data?.data?.lastOrder?.id,
      importationId: '',
      detailsLink: `/recargas/acompanhamento#orderId="${data?.data?.lastOrder?.id}"`,
    },
    layoutOptions: optionsMock,
    redoLastOrder,
    uploadImportFile,
    isLoading: {
      upload: uploadImportFile.isLoading,
      redoing: redoLastOrder.isLoading,
      lastOrder: isLoadingLastOrder,
      options: false,
    },
  }
}
