import { EBenefitType, EEmployeeBenefitStatus, EStatusCompra, EStatusPedido, ETipoPedido } from '../enums'

export const ORDER_STATUS = {
  [EStatusPedido.NoCarrinho]: 'Rascunho',
  [EStatusPedido.Efetivando]: 'Efetivando',
  [EStatusPedido.Efetivado]: 'Processando Pagamento',
  [EStatusPedido.AguardandoProcessamento]: 'Aguardando Processamento',
  [EStatusPedido.EmProcessamento]: 'Em Processamento',
  [EStatusPedido.EmAndamento]: 'Processamento Operadora',
  [EStatusPedido.DisponivelRetirada]: 'Disponível para Retirada',
  [EStatusPedido.Entrega]: 'Em Entrega',
  [EStatusPedido.Finalizado]: 'Concluído',
  [EStatusPedido.Cancelando]: 'Cancelando',
  [EStatusPedido.Cancelado]: 'Cancelado',
}

export const ORDER_TYPES = {
  [ETipoPedido.PrimeiraViaVtDealer]: '1 via',
  [ETipoPedido.SegundaViaVtDealer]: '2 via',
  [ETipoPedido.RecargaVtDealer]: 'Dealer',
  [ETipoPedido.RecargaVtBroker]: 'Broker',
  [ETipoPedido.Deposito]: 'Deposito',
  [ETipoPedido.Compra]: 'Compra',
}

export const PURCHASE_STATUS = {
  [EStatusCompra.Carrinho]: 'Rascunho',
  [EStatusCompra.Efetivando]: 'Efetivando',
  [EStatusCompra.ProcessandoFormaPagamento]: 'Processando Pagamento',
  [EStatusCompra.AguardandoPagamento]: 'Aguardando Pagamento',
  [EStatusCompra.EmAndamento]: 'Em Andamento',
  [EStatusCompra.Finalizado]: 'Concluído',
  [EStatusCompra.Cancelando]: 'Cancelando',
  [EStatusCompra.Cancelado]: 'Cancelado',
}

export const BENEFIT_TYPES = {
  [EBenefitType.Food]: 'Vale Alimentação',
  [EBenefitType.Fuel]: 'Vale Combustível',
  [EBenefitType.Snack]: 'Vale Refeição',
  [EBenefitType.Transport]: 'Vale Transporte Eletrônico',
  [EBenefitType.TransportPaper]: 'Vale Transporte Papel',
}

export const EMPLOYEE_BENEFIT_STATUS = {
  [EEmployeeBenefitStatus.Active]: 'Ativo',
  [EEmployeeBenefitStatus.Inactive]: 'Inativo',
  [EEmployeeBenefitStatus.Blocked]: 'Bloqueado',
  [EEmployeeBenefitStatus.SynchronismError]: 'Erro de Sincronismo',
  [EEmployeeBenefitStatus.WaitingSynchronism]: 'Aguardando Sincronismo',
}
