import ImportResult from './importResults'
import { ContentButtons, ExplanationText, ProgressPosition, ContentDropzone } from './style'
import { Content, Panel, Title, Dropzone, Spacing, Breadcrumb, Modal } from 'presentation/components'
import { TEN_MB } from '@/utils/constants'
import { Button } from 'presentation/components/atoms'
import { useImportEmployee } from 'presentation/pages/employees/hooks/useImportEmployee'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const EmployeesImport = () => {
  const { resources } = useAccessProfile({ resource: 'colaborador' })

  const { downloadFileExcel, downloadFileTxt, error, handleDrop, response, setError, uploadingFile } =
    useImportEmployee()

  const formats = resources({ item: 'colaborador_cadastrar_excel' })
    ? {
        format: 'txt/xlsx',
        accept: ['.txt', '.xlsx'],
      }
    : {
        format: 'txt',
        accept: ['.txt'],
      }

  return (
    <>
      <Content>
        <Title>Cadastro de Colaborador por Importação</Title>
        <Spacing top='9px' bottom='9px'>
          <Breadcrumb />
        </Spacing>
        <Panel style={{ minHeight: '700px' }} noPadding>
          {!uploadingFile.uploading && !response && (
            <>
              <ExplanationText>
                Cadastre seus colaboradores através da importação do arquivo. Baixe nosso modelo, preencha com as
                informações de cadastro dos seus colaboradores respeitando a regra de obrigatoriedade e importe o
                arquivo. Obs: Você será notificado caso o usuário já possua cadastro ou ocorra erro no processo.
              </ExplanationText>
              <Spacing top='32px' />
              <ContentButtons>
                {resources({ item: 'colaborador_importacao_txt' }) ? (
                  <Button
                    icon='download'
                    style={{ width: '250px', marginRight: 20 }}
                    onClick={downloadFileTxt}
                    variant='contained'
                  >
                    Baixar arquivo TXT
                  </Button>
                ) : null}
                {resources({ item: 'colaborador_cadastrar_excel' }) ? (
                  <Button icon='download' style={{ width: '250px' }} onClick={downloadFileExcel} variant='contained'>
                    Baixar arquivo Excel
                  </Button>
                ) : null}
              </ContentButtons>
              <Spacing top='32px' />
              <ContentDropzone>
                <Dropzone
                  accept={formats.accept}
                  onDrop={handleDrop}
                  formatsLabel={formats.format}
                  multiple={false}
                  maxSize={TEN_MB}
                  setError={setError}
                />
              </ContentDropzone>
            </>
          )}
          {uploadingFile?.uploading && !response && (
            <ProgressPosition>
              <Dropzone.Progress
                uploadingFile={uploadingFile}
                onCancel={() => uploadingFile?.cancelToken?.cancel('cancel')}
              />
            </ProgressPosition>
          )}
          {!!response && <ImportResult response={response} onDrop={handleDrop} />}
        </Panel>
      </Content>
      {error && (
        <Modal onlyConfirm open={error} confirm={() => setError(null)}>
          {error}
        </Modal>
      )}
    </>
  )
}

export default EmployeesImport
