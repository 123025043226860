import { useRef } from 'react'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useNotifications } from 'main/hooks'
import { errorHandler } from 'main/utils/functions/errorHandler'

export const QueryProvider = ({ children }: { children: any }) => {
  const notifications = useNotifications()

  const onErrorQuery = (error: any, query: any) => {
    if (!query?.meta?.silent) notifications.push(errorHandler({ error: error?.response?.data?.errors }))
  }

  const onErrorMutation = (error: any, query: any, context: any, mutation: any) => {
    if (!mutation?.options?.meta?.silent) notifications.push(errorHandler({ error: error?.response?.data?.errors }))
  }

  const queryClient = useRef<QueryClient>(
    new QueryClient({
      queryCache: new QueryCache({ onError: onErrorQuery }),
      mutationCache: new MutationCache({ onError: onErrorMutation }),
    }),
  )

  return <QueryClientProvider client={queryClient.current}>{children}</QueryClientProvider>
}
