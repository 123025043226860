import { AccountPersistentStore } from '@/main/store'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { useAtomValue } from 'jotai'
import { ReactNode } from 'react'

// Componente feito para alternar entre duas integrações
// de um mesmo componente em função da nova API de pedidos
// e a necessidade de coexistência com a antiga.

export const IntegrationSwapper = ({
  isDealer = true,
  components: { ifIsMotiva, ifIsNotMotiva },
}: {
  isDealer?: boolean
  components: {
    ifIsMotiva: ReactNode
    ifIsNotMotiva: ReactNode
  }
}) => {
  const account = useAtomValue(AccountPersistentStore)

  //liberando broker novo para motiva
  const isMotiva = !account.company.newBroker || isDealer
  return isMotiva ? ifIsMotiva : ifIsNotMotiva
}
