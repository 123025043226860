import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { EBindCardForm } from '../benefitBindDialog'
import { Divider, formatMoney, Grid, MaskedTextField, Typography } from '@stationkim/front-ui'
import React from 'react'
import { RateChip } from './rateChip'
import { Theme } from '@mui/material'

interface CardRatesFormProps {
  id: string
  form: UseFormReturn<EBindCardForm>
  visible?: boolean
}

export const CardRatesForm = ({ form, id, visible }: CardRatesFormProps) => {
  const { fields, remove } = useFieldArray({
    name: 'products',
    control: form.control,
  })
  const canRemove = fields.length > 1

  const products = form.watch('products')
  const productsTotal = products?.reduce((total, product) => (total += product.total), 0) || 0

  return (
    <>
      {visible && (
        <Typography sx={{ marginBottom: '32px' }}>
          Informe, para cada tarifa registrada, o número de vezes o qual ela será utilizada
        </Typography>
      )}
      <form id='employeeBenefitBindRatesForm' style={{ display: !visible && 'none' }}>
        <Grid container spacing='16px' sx={{ position: 'relative' }}>
          {fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name={`products.${index}.label`}
                    render={({ field: { name, value } }) => (
                      <RateChip
                        label={`Tarifa ${index + 1}`}
                        value={value}
                        id={`${id}_${name}`}
                        onClick={canRemove ? () => remove(index) : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    control={form.control}
                    name={`products.${index}.quantity`}
                    render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
                      <MaskedTextField
                        mask={{ mask: '00' }}
                        placeholder='00'
                        value={value}
                        inputRef={ref}
                        onChange={(newValue) => {
                          onChange(newValue)
                        }}
                        label='Qtd.'
                        id={`${id}_${name}`}
                        error={Boolean(error)}
                        helperText={error?.message}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    control={form.control}
                    name={`products.${index}.quantity`}
                    render={() => {
                      const formValues = form.getValues()
                      const product = formValues.products[index]
                      const totalValue = String(product.quantity * product.value).replace('.', ',')
                      return (
                        <MaskedTextField
                          mask={'monetary'}
                          value={totalValue}
                          label='Valor Diário'
                          id={`${id}_products.${index}.total`}
                          blocked
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Divider />
                </Grid>
              </React.Fragment>
            )
          })}
          <Grid
            item
            xs={12}
            sx={(theme: Theme) => ({
              textAlign: 'right',
              position: { xs: 'sticky', sm: 'initial' },
              bottom: 0,
              top: 0,
              background: theme.palette.common.white,
              paddingRight: '8px',
            })}
          >
            <Typography variant='h5'>Total: R$ {formatMoney(productsTotal)}</Typography>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
