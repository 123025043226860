import { SxProps } from '@mui/material'
import { StyledChip } from './styles'

type TChipStatus = 'active' | 'inactive' | 'error'

export interface StatusChipProps {
  status?: TChipStatus
  sx?: SxProps
}

const text: Record<TChipStatus, string> = {
  active: 'Ativo',
  error: 'Erro',
  inactive: 'Inativo',
}

export const StatusChip = ({ status = 'active', sx }: StatusChipProps) => {
  return <StyledChip label={text[status]} status={status} sx={sx} />
}
