import api from '@/infra/api'
import { AccountPersistentStore } from '@/main/store'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'

export const useOrderEmployeeDataFetch = () => {
  const account = useAtomValue(AccountPersistentStore)
  const companyId = account?.company?.id

  const getEmployeeData = useMutation({
    mutationKey: ['employeeData'],
    mutationFn: ({
      employeeIdentifier,
      layoutConfiguracaoId,
    }: {
      employeeIdentifier: string
      layoutConfiguracaoId: number
    }) =>
      api.post('/rh-pedidos-bff/importacao/buscarColaboradores', {
        layoutConfiguracaoId,
        cpfs: [employeeIdentifier],
        matriculas: [employeeIdentifier],
        idEmpresa: companyId,
      }),
    meta: { silent: true },
  })

  return {
    getEmployeeData,
  }
}
