import { useState } from 'react'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { Box, Icon, Menu, MenuItem } from '@stationkim/front-ui'
import { EEmployeeBenefitStatus } from '@/main/enums'
import { TBenefit } from '../hooks/useEmployeeBenefits'

export interface ActionMenuProps {
  id?: string
  onEdit: (benefit: TBenefit) => void
  onStatusChange: (benefit: TBenefit) => void
  benefit: TBenefit
}

export const ActionMenu = ({ id, benefit, onEdit, onStatusChange }: ActionMenuProps) => {
  const [benefitMenuRef, setBenefitMenuRef] = useState<EventTarget & HTMLButtonElement>()
  const disabled = [
    EEmployeeBenefitStatus.Blocked,
    EEmployeeBenefitStatus.WaitingSynchronism,
    EEmployeeBenefitStatus.SynchronismError,
  ].includes(benefit.status)
  const isBenefitActive = benefit.status === EEmployeeBenefitStatus.Active

  return (
    <Box sx={{ margin: 'auto', width: 'fit-content' }}>
      <button
        style={{ all: 'unset', cursor: 'pointer' }}
        id={`${id}_menu_trigger`}
        onClick={(event) => {
          event.stopPropagation()
          setBenefitMenuRef(event.currentTarget)
        }}
      >
        <Icon icon={faEllipsis} />
      </button>

      {Boolean(benefitMenuRef) && (
        <Menu
          id={`${id}_menu`}
          anchorEl={benefitMenuRef}
          open={true}
          sx={{ marginTop: '-8px', width: '120px' }}
          onClose={() => setBenefitMenuRef(null)}
          onClick={(e) => {
            e.stopPropagation()
            setBenefitMenuRef(null)
          }}
        >
          <MenuItem id={`${id}_menu_option_0`} onClick={() => onEdit(benefit)} disabled={disabled}>
            Editar
          </MenuItem>
          <MenuItem id={`${id}_menu_option_1`} onClick={() => onStatusChange(benefit)} disabled={disabled}>
            {isBenefitActive ? 'Inativar' : 'Ativar'}
          </MenuItem>
        </Menu>
      )}
    </Box>
  )
}
