import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'
import qs from 'qs'

export const getCardSignaturesReport = (orderId, query) => {
  return apiCallHandler(() =>
    api.get(`${process.env.REACT_APP_REPORT_API_HOST}/orders/${orderId}/cards/signatures/pdf`, {
      responseType: 'blob',
    }),
  )
}

export const getCardReceipt = (orderCode) => {
  return apiCallHandler(() =>
    api.get(`${process.env.REACT_APP_REPORT_API_HOST}/orders/${orderCode}/cards/receipt/pdf`, {
      responseType: 'blob',
    }),
  )
}

export const getRechargeSignaturesReport = (orderId, query) => {
  return apiCallHandler(() =>
    api.get(`${process.env.REACT_APP_REPORT_API_HOST}/orders/${orderId}/recharges/signatures/pdf`, {
      responseType: 'blob',
    }),
  )
}

export const getEmployeesReport = (companyId, query) => {
  return apiCallHandler(() =>
    api.get(`${process.env.REACT_APP_REPORT_API_HOST}/companies/${companyId}/employees/pdf`, {
      responseType: 'blob',
    }),
  )
}

export const getRechargeReceipt = (orderCode) => {
  return apiCallHandler(() =>
    api.get(`${process.env.REACT_APP_REPORT_API_HOST}/orders/${orderCode}/recharges/receipt/pdf`, {
      responseType: 'blob',
    }),
  )
}

export const getCardOrdersReport = (orderNumber, format, isNewBroker) => {
  return apiCallHandler(() =>
    api.get(
      `${process.env.REACT_APP_REPORT_API_HOST}/orders/${orderNumber}/cards/${format}?isNewOrder=${isNewBroker}`,
      {
        responseType: 'blob',
      },
    ),
  )
}

export const getRechargeOrdersReport = (orderNumber, format, isNewBroker) => {
  return apiCallHandler(() =>
    api.get(`/rh-bff/${orderNumber}/recharges/${format}?isNewOrder=${isNewBroker}`, {
      responseType: 'blob',
    }),
  )
}

export const getBalanceProjectionOrderReport = (payload, format, isNewBroker) => {
  return apiCallHandler(() =>
    api.get(`/rh-bff/balance-projection/${format}?isNewOrder=${isNewBroker}`, {
      responseType: 'blob',
      params: payload,
      paramsSerializer: (params) =>
        qs.stringify(params, {
          arrayFormat: 'repeat',
        }),
    }),
  )
}

export const getCardsLoadedAndNoLoaded = (orderCode) => {
  return apiCallHandler(() =>
    api.get(`/rh-bff/${orderCode}/cards/recharges/pdf`, {
      responseType: 'blob',
    }),
  )
}

export const getSyntheticCardBalanceReport = (payload) => {
  return apiCallHandler(() =>
    api.post(`/rh-bff/Synthetic/pdf`, payload, {
      responseType: 'blob',
    }),
  )
}

export const getAnaliticCardBalanceReport = (payload, format = 'pdf') => {
  return apiCallHandler(() =>
    api.post(`/rh-bff/analytical/${format}`, payload, {
      responseType: 'blob',
    }),
  )
}

export const getCardsWithoutRechargeReport = (query = '') => {
  return apiCallHandler(() =>
    api.get(`/rh-bff/CardsWithoutRecharge/pdf${query}`, {
      responseType: 'blob',
    }),
  )
}

export const getBlockedCardsWithoutSecondCopyReport = (query = '') => {
  return apiCallHandler(() =>
    api.get(`/rh-bff/Blocked/pdf${query}`, {
      responseType: 'blob',
    }),
  )
}

export const getStatementReport = (query = '') => {
  return apiCallHandler(() =>
    api.get(`${process.env.REACT_APP_REPORT_API_HOST}/financial/statement/pdf${query}`, {
      responseType: 'blob',
    }),
  )
}
