import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import Img from '@/presentation/assets/images/home.png'
import ImgAdv from './formImage.png'
import { Content } from '@/presentation/components'
import { Dialog, DialogContent, Icon } from '@stationkim/front-ui'
import { AccountPersistentStore, GlobalThemePersistentStore } from '@/main/store'
import { defaultTheme } from '@/config/themes/defaultTheme'
import { Image } from './styles'
import { faX } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
  const account = useAtomValue(AccountPersistentStore)
  const [dialogOpen, setDialogOpen] = useState(true)
  const [theme, setTheme] = useAtom(GlobalThemePersistentStore)
  useEffect(() => {
    if (!account.resale?.theme) return
    if (Object.keys(JSON.parse(account.resale.theme)).length === 0) {
      setTheme(defaultTheme)
      return
    }
    setTheme(_.merge(theme, JSON.parse(account.resale.theme)))
  }, [])
  // todo remove this as soon as possible
  const isTargetResale =
    useSelector((state: any) => state?.auth?.user?.resaleId) === '79638307-2d03-4780-8159-971b6a4c22a8'
  const isMessageValid = new Date() < new Date('2025/05/01')
  return (
    <Content>
      <Image src={Img} style={{ maxHeight: '100%' }} />
      <Dialog open={dialogOpen} maxWidth='lg'>
        <DialogContent sx={{ overflow: 'hidden', position: 'relative', minWidth: '570px' }}>
          <Icon icon={faX}
            onClick={() => setDialogOpen(false)}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              cursor: 'pointer',
              color: 'white'
            }} />
          <Image src={ImgAdv} />
          <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=t6CUIcrgxkWdjW9W1C_zJKf2e0ZPRXNFjIdBt9TW1C5UOUswVzVWN1BMQ0g1Nk9GRlNVMjVTM1dGSS4u' target='_blank' rel="noreferrer">
            <button style={{
              border: 'none',
              height: '10.58%',
              width: '35.84%',
              position: 'absolute',
              right: '13.65%',
              bottom: '12.24%',
              background: 'transparent',
              cursor: 'pointer'
            }} />
          </a>
        </DialogContent>
      </Dialog>
    </Content>
  )
}

export default Home
