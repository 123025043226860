import { faCircleExclamation, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { Box, Icon, Menu, Tooltip } from '@stationkim/front-ui'
import { EMPLOYEE_OPERATOR_INTEGRATION, EMPLOYEE_STATUS } from '@/utils/constants'
import { Theme } from '@mui/material'
import IconList from '../components/employeeTable/parts/iconList'
import { ColorDot } from '@/presentation/components/atoms'
import { EmployeeRowMenu } from '../components/employeeRowMenu'

export const employeesTableColumnsConfig = ({ order, onEdit }) => {
  return [
    {
      columnDisplayName: 'Colaborador',
      onOrder: ({ order: direction }: { order: boolean }) => order('fullName', direction),
      columnValue: ({ row }) => {
        const isWaitingSync = row.integrationsStatus === EMPLOYEE_OPERATOR_INTEGRATION.RUNNING
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
            {row.fullName}
            {row.integrationsStatus === EMPLOYEE_OPERATOR_INTEGRATION.OK ? null : (
              <Tooltip title={isWaitingSync ? 'Aguardando Sincronismo' : 'Erro de Sincronismo'}>
                <Box>
                  <Icon
                    icon={isWaitingSync ? faClockRotateLeft : faCircleExclamation}
                    sx={(theme: Theme) => ({
                      color: isWaitingSync ? theme.palette.warning.main : theme.palette.error.main,
                    })}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
    {
      columnDisplayName: 'Matrícula',
      columnValue: ({ row }) => row.registration,
    },
    {
      columnDisplayName: 'Departamento',
      columnValue: ({ row }) => row.departmentName,
      onOrder: ({ order: direction }: { order: boolean }) => order('departmentName', direction),
    },
    {
      columnDisplayName: 'Benefício',
      columnValue: ({ row }) => {
        return !row.status ? '' : <IconList itemsList={row.benefits.length > 0 ? row.benefits : []} />
      },
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => {
        const isEmployeeActive = row.status === EMPLOYEE_STATUS.ACTIVE
        const isEmployeeInactive = row.status === EMPLOYEE_STATUS.INACTIVE
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ColorDot color={isEmployeeActive ? 'success' : isEmployeeInactive ? 'grey' : 'error'} />
            {EMPLOYEE_STATUS[row.status]}
          </Box>
        )
      },
    },
    {
      columnDisplayName: 'Ações',
      preventLink: true,
      align: 'center',
      columnValue: ({ row, index }) => (
        <EmployeeRowMenu employee={row} index={index} onChangeStatus={onEdit} onRemove={() => null} />
      ),
    },
  ]
}
