import { useState } from 'react'
import { Input, ModalV2, Spinner } from 'presentation/components'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { CustomContainer, InputWrapper, ModalTitle } from '../styles'
import { Label } from './styles'
import { Button } from '@/presentation/components/atoms'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { ReportFormatSelector } from '../../components/reportFormatSelector'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'

const GenericOrderReportFilter = ({
  open = true,
  loading,
  title = 'Relatório de Pedido',
  onClose,
  availableFormatsOptions = [
    {
      value: 'pdf',
      label: 'PDF',
    },
  ],
  onConfirm,
  selectNewBroker,
}) => {
  const [selectedFormat, setSelectedFormat] = useState(availableFormatsOptions[0].value)
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  })
  const notification = useNotifications()
  const onSubmit = (data) => {
    if (!data.cardOrder) {
      notification.push({
        content: 'Para exportar o relatório favor informar número do pedido',
        type: EMessageType.Warning,
      })
      return
    }
    onConfirm(data.cardOrder, selectedFormat)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='genericOrderReportFilterForm'>
          <CustomContainer fluid>
            <Row>
              <Col>
                <ModalTitle>{title}</ModalTitle>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Label>N° d{selectNewBroker?.value === 0 ? 'a Compra' : 'o Pedido'}</Label>
                <InputWrapper>
                  <Input
                    placeholder={`Insira o número d${selectNewBroker?.value === 0 ? 'a Compra' : 'o Pedido'}`}
                    {...register('cardOrder')}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/, '').slice(0, 18)
                    }}
                  />
                </InputWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={7}>
                <ReportFormatSelector
                  availableFormatsOptions={availableFormatsOptions}
                  onChange={(value) => setSelectedFormat(value)}
                  value={selectedFormat}
                  customRadio={Input.CheckBox}
                  sx={{ margin: '8px 0' }}
                />
              </Col>
              {selectNewBroker?.setNewBroker ? (
                <Col sm={5}>
                  <ButtonSelector
                    value={selectNewBroker?.value}
                    onChange={({ targetIndex }) => selectNewBroker?.setNewBroker(targetIndex)}
                  >
                    <ButtonSelectorOption>Broker</ButtonSelectorOption>
                    <ButtonSelectorOption>Dealer</ButtonSelectorOption>
                  </ButtonSelector>
                </Col>
              ) : null}
            </Row>
            <Row justify='end'>
              <Col sm='content'>
                <Button type='submit' variant='contained'>
                  Gerar Relatório
                </Button>
              </Col>
            </Row>
          </CustomContainer>
        </form>
      )}
    </ModalV2>
  )
}

export default GenericOrderReportFilter
