import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

interface IApiResponse {
  benefitId: string
  operatorCardId: string
  status: number
  processType: number
  benefitType: number
  operatorId: string
  dailyRate: number
  dailyValue: null
  operatorRegistration: string
}

export type TBenefit = IApiResponse

interface UseEmployeeBenefitReturn {
  benefit: TBenefit
  isLoading: boolean
}

interface UseEmployeeBenefitProps {
  benefitId: string
  enabled: boolean
}
export const useEmployeeBenefit = ({ benefitId, enabled }: UseEmployeeBenefitProps): UseEmployeeBenefitReturn => {
  const { data, isFetching } = useQuery<AxiosResponse<IApiResponse>>({
    queryKey: ['employeeBenefit', benefitId],
    queryFn: () => api.get(`/rh-bff/Benefit/edit/${benefitId}`),
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: Boolean(benefitId) && enabled,
  })

  const benefit: UseEmployeeBenefitReturn['benefit'] = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return null
    return rawData
  }, [data])

  return {
    benefit,
    isLoading: isFetching,
  }
}
