export enum EFormMode {
  View = '1',
  Add = '2',
  Edit = '3',
}

export enum EEmployeeStatus {
  Inactive = '0',
  Active = '1',
}

export enum ETipoPagamento {
  Gratuito,
  Boleto,
  Pix,
  CarteiraSK,
  CarteiraOperadora,
}

export enum EEmployeeGender {
  Male = 1,
  Female,
  NonBinary,
}

export enum ETipoPedido {
  PrimeiraViaVtDealer = 1,
  SegundaViaVtDealer = 2,
  RecargaVtDealer = 3,
  Deposito = 4,
  RecargaVtBroker = 5,
  Compra = 6,
}

export enum EMascaras {
  Texto,
  Cpf,
  Cnpj,
  Produto,
  Monetario,
  Percentual,
  Numerico,
}

export enum EStatusPedido {
  NoCarrinho,
  Efetivando,
  Efetivado,
  AguardandoProcessamento,
  EmProcessamento,
  EmAndamento,
  DisponivelRetirada,
  Entrega,
  Finalizado,
  Cancelando,
  Cancelado,
}

export enum EStatusCompra {
  Carrinho,
  Efetivando,
  ProcessandoFormaPagamento,
  AguardandoPagamento,
  EmAndamento,
  Finalizado,
  Cancelando,
  Cancelado,
}

export enum ETipoCampoTabela {
  Tabela,
  ModalColaborador,
  TabelaEModalColaborador,
}

export enum EBenefitType {
  Transport = 1,
  Food,
  Snack,
  Fuel,
  TransportPaper,
}

export enum EEmployeeBenefitStatus {
  Active = 1,
  Inactive,
  Blocked,
  WaitingSynchronism,
  SynchronismError,
}

export enum ECardUsage {
  None = 0,
  Dealer,
  Broker,
}
