import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import blobDownload from '@/utils/blobDownload'

interface IUseExtract {
  employeeId: string
  filter: string
  employeeName: string
  visible?: boolean
}

export const useExtract = ({ employeeId, filter, employeeName, visible = false }: IUseExtract) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadExtract = async () => {
    setIsDownloading(true)

    const response = await api.get(`/reports-service/employees/${employeeId}/usage-history/pdf/${filter}`, {
      responseType: 'blob',
    })

    if (response.data) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Relatório de extratos - ${employeeName}.pdf`
      blobDownload(blob, filename)
    }

    setIsDownloading(false)
  }

  const { data, isLoading } = useQuery({
    queryKey: ['extract', employeeId, filter],
    queryFn: () => api.get(`rh-bff/employees/${employeeId}/usage-history/${filter}`),
    refetchOnWindowFocus: false,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: visible,
  })

  return {
    downloadExtract,
    data: data?.data,
    isLoading,
    isDownloading,
  }
}
