import { EFormMode } from '@/main/enums'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { EMPLOYEE_STATUS } from '@/utils/constants'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { Box, Icon, Menu, MenuItem } from '@stationkim/front-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//todo reativar itens com dependencia de modais

const initialMenuRef = {
  anchor: null,
  index: null,
}

interface EmployeeRowMenuProps {
  index: number
  employee: any
  onRemove: () => void
  onChangeStatus: (employee: any) => void
}

export const EmployeeRowMenu = ({ employee, index, onChangeStatus, onRemove }: EmployeeRowMenuProps) => {
  const navigate = useNavigate()
  const { resources } = useAccessProfile({ resource: 'colaborador' })
  const [employeeMenuRef, setEmployeeMenuRef] = useState(initialMenuRef)
  const isEmployeeActive = employee.status === EMPLOYEE_STATUS.ACTIVE
  const isEmployeeInactive = employee.status === EMPLOYEE_STATUS.INACTIVE

  const onEdit = (employeeId: string) =>
    navigate(`/colaboradores/visualizar#employeeId="${employeeId}"`, {
      state: { formMode: EFormMode.Edit },
    })

  return resources({ item: 'colaborador_gerenciar' }) ? (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Icon
        icon={faEllipsis}
        onClick={(event) => {
          setEmployeeMenuRef({
            anchor: event.currentTarget,
            index: index,
          })
        }}
      />
      <Menu
        id={'employee-menu-' + index}
        anchorEl={employeeMenuRef.anchor}
        open={employeeMenuRef.index === index}
        onClose={() => setEmployeeMenuRef(initialMenuRef)}
        onClick={(e) => {
          e.stopPropagation()
          setEmployeeMenuRef(initialMenuRef)
        }}
      >
        <MenuItem onClick={(e) => onEdit(employee.employeeId)}>Editar</MenuItem>
        {!isEmployeeActive && !isEmployeeInactive ? null : (
          <MenuItem onClick={() => onChangeStatus(employee)}>{!isEmployeeActive ? 'Ativar' : 'Inativar'}</MenuItem>
        )}
        {employee.removable && <MenuItem onClick={(e) => onRemove(/* e, employee */)}>Excluir</MenuItem>}
      </Menu>
    </Box>
  ) : (
    '-'
  )
}
