import { useAtom, useAtomValue } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'
import { OldDealerList } from './oldDealerList'
import { RechargesList } from './rechargesList'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { AccountPersistentStore } from '@/main/store'

const lists = [RechargesList, OldDealerList]
const listSelectorAtom = atomWithHash('selectedList', 0)

const Recharges = () => {
  const account = useAtomValue(AccountPersistentStore)

  const { resources } = useAccessProfile({ resource: 'recarga' })

  const [visibleList, setVisibleList] = useAtom(listSelectorAtom)
  const canOrderBroker = resources({ item: 'recarga_pedidobroker' }) && account.company.newBroker
  const listSelector = (
    <ButtonSelector value={visibleList} onChange={({ targetIndex }) => setVisibleList(targetIndex)}>
      {canOrderBroker ? <ButtonSelectorOption>Broker</ButtonSelectorOption> : <p />}
      <ButtonSelectorOption>Dealer</ButtonSelectorOption>
    </ButtonSelector>
  )
  const Component = canOrderBroker ? lists[visibleList] : lists[1]
  return <Component listSelector={listSelector} />
}

export default Recharges
