import React, { useEffect, useMemo } from 'react'
import { IMask } from 'react-imask'
import { Controller, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MaskedTextField,
  Skeleton,
  TextField,
} from '@stationkim/front-ui'
import { EMascaras } from '@/main/enums'
import { IUseImportResultsReturn } from '../../hooks/useImportResults'
import { useCreateEmployee } from '../../hooks/useCreateEmployee'
import { NULL_ID } from '@/utils/constants'
import { useOrderEmployeeDataFetch } from '../../hooks/useOrderEmployeeDataFetch'
type TIdentifiersInUse = string[]
type TColumns = IUseImportResultsReturn['columns']

interface AddEmployeeToOrderProps {
  onClose: () => void
  columns: TColumns
  identifiersInUse: TIdentifiersInUse
  layoutId: number
  companyId: string
  importId: number
  keyField: string
  //remove later todo
  rows: any
  id: number
}

const _defaultValues = {
  id: 0,
  importacaoId: 0,
  layoutConfiguracaoId: 0,
  idEmpresa: '',
  idCartaoOperadora: NULL_ID,
  idOperadora: NULL_ID,
  valorTarifa: 0,
  linha: 0,
  nome: '',
  cpf: '',
  nomeDaMae: '',
  sexo: '',
  dataNascimento: null,
  cep: '',
  cidade: '',
  uf: '',
  endereco: '',
  bairro: '',
  numero: '',
  novoColaborador: true,
  matricula: '',
  codigoProduto: 0,
  qtdDiaria: 0,
  qtdBeneficioMes: 0,
  numeroCartao: '',
  valorDiario: 0,
  valorTotal: 0,
}

const fieldMask = {
  [EMascaras.Cnpj]: 'cnpj',
  [EMascaras.Cpf]: 'cpf',
  [EMascaras.Monetario]: 'monetary',
  [EMascaras.Produto]: { mask: IMask.MaskedRange, maxLength: 4, autofix: true, from: 0, to: 9999 },
  [EMascaras.Numerico]: 'number',
  [EMascaras.Percentual]: 'percentual',
}

const getDefaultValues = (columns: TColumns) => {
  const values = columns.reduce((final, column) => {
    return { ...final, [column.dataKey]: '' }
  }, _defaultValues)
  return values
}
interface IGenerateFormArgs<T> {
  columns: TColumns
  form: UseFormReturn<T>
  keyFieldName: string
  identifiersInUse: TIdentifiersInUse
  getEmployeeData: ReturnType<typeof useOrderEmployeeDataFetch>['getEmployeeData']
  defaultValues: TFormValues
  isLoading: boolean
}

const generateForm = <T,>({
  columns,
  form,
  identifiersInUse,
  keyFieldName,
  getEmployeeData,
  defaultValues,
  isLoading,
}: IGenerateFormArgs<T>) => {
  const keyValue = form.watch(keyFieldName as any)
  return columns.map((column, index: number) => {
    const label = String(column.title).toLocaleLowerCase()
    const isDisabled =
      (column.dataKey !== keyFieldName && (!Boolean(keyValue) || form.formState?.errors?.[keyFieldName])) ||
      !column.editable
    const isIdentifier = column.dataKey === keyFieldName
    let rules = column.rules
    if (isIdentifier)
      rules = {
        ...rules,
        validate: {
          ...rules?.validate,
          isUnique: (value) => {
            return identifiersInUse.some((identifier) => identifier === value) ? 'Identificador já no pedido' : true
          },
        },
      }
    return (
      <React.Fragment key={`add_employee_field_${index}`}>
        <Grid item xs={12} sm={6}>
          <Controller<T>
            control={form.control}
            name={column.dataKey as any}
            rules={!column.editable ? null : rules}
            render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
              if (getEmployeeData.isLoading || isLoading)
                return <Skeleton variant='rounded' height='46px' width='100%' />

              const baseProps: Record<string, any> = {
                label: label,
                inputRef: ref,
                value: value,
                onChange: onChange,
                helperText: error?.message,
                error: Boolean(error),
                placeholder: 'Preencha',
                blocked: isDisabled,
              }
              if (isIdentifier && !Boolean(error)) {
                baseProps.onBlur = () => {
                  if (String(value).length > 0)
                    getEmployeeData.mutate(
                      { employeeIdentifier: String(value), layoutConfiguracaoId: 2 },
                      {
                        onError: () => {
                          form.reset({ ...defaultValues, [keyFieldName]: value } as any)
                        },
                        onSuccess: (res) => {
                          if (res?.data?.colaborador) {
                            //Remove this deletes when the get turn more stable
                            delete res?.data?.colaborador.layoutConfiguracaoId
                            delete res?.data?.colaborador.idEmpresa
                            delete res?.data?.colaborador.importacaoId

                            form.reset({ ...defaultValues, ...res?.data?.colaborador }, { keepDefaultValues: true })
                          }
                        },
                      },
                    )
                }
              }
              return column.maskType === EMascaras.Texto ? (
                <TextField {...baseProps} />
              ) : (
                <MaskedTextField {...baseProps} mask={fieldMask[column.maskType]} unmask />
              )
            }}
          />
        </Grid>
      </React.Fragment>
    )
  })
}

type TFormValues = typeof _defaultValues
export const AddEmployeeToOrder = ({
  onClose,
  columns,
  identifiersInUse,
  importId,
  layoutId,
  companyId,
  keyField,
  rows,
  id,
}: AddEmployeeToOrderProps) => {
  const defaultValues = useMemo(() => {
    return {
      ...getDefaultValues(columns),
      layoutConfiguracaoId: layoutId,
      idEmpresa: companyId,
      id: 0,
      importacaoId: id,
    }
  }, [columns, layoutId, companyId, id])
  const { createEmployee } = useCreateEmployee({ onClose })

  const form = useForm({
    defaultValues,
    mode: 'onChange',
  })

  //Maybe this need to be changed later
  //we don't have a dynamic way to calculate the total value
  const [dailyValue, monthlyAmount] = form.watch(['valorDiario', 'qtdBeneficioMes'] as any)
  useEffect(() => {
    form.setValue('valorTotal', dailyValue * monthlyAmount)
  }, [dailyValue, monthlyAmount, form])
  //=========================================================

  const { getEmployeeData } = useOrderEmployeeDataFetch()

  const onSubmit: SubmitHandler<TFormValues> = (data: any) => {
    if (rows.length > 0) {
      data.idOperadora = rows[0].idOperadora
      data.idCartaoOperadora = rows[0].idCartaoOperadora
    }
    data.qtdBeneficioMes = Number(data.qtdBeneficioMes || 0)
    data.valorDiario = Number(data.valorDiario || 0)
    data.valorTotal = Number(data.valorTotal || 0)
    delete data.guidImportacao
    delete data.erros
    createEmployee.mutate(data)
  }

  return (
    <Dialog open>
      <DialogTitle>Adicionar Colaborador</DialogTitle>
      <DialogContent>
        <form id='addEmployeeToOrderForm' onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing='16px' sx={{ marginTop: '32px', textTransform: 'capitalize' }}>
            {generateForm({
              columns,
              form,
              identifiersInUse,
              keyFieldName: keyField,
              getEmployeeData,
              defaultValues,
              isLoading: createEmployee.isLoading,
            })}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ gap: '16px', marginTop: '32px' }}>
        <Button
          variant='outlined'
          sx={{ filter: 'grayscale(100)' }}
          onClick={onClose}
          fullWidth
          disabled={getEmployeeData.isLoading || createEmployee.isLoading}
        >
          Cancelar
        </Button>
        <Button
          variant='contained'
          fullWidth
          type='submit'
          form='addEmployeeToOrderForm'
          disabled={
            getEmployeeData.isLoading || Object.keys(form.formState.errors).length > 0 || createEmployee.isLoading
          }
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
