/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { Box, Button, Divider, Paper, Tooltip, Typography } from 'presentation/components/atoms'
import { NoItemPaper, TextRates } from './styles'
import { formatMoney, getResaleSiteFromUrl } from 'utils/functionUtils'
import { CheckboxField, MaskedTextField, ValuesSummary } from 'presentation/components/molecules'
import { MethodPayment } from './components/methodPayment/methodPayment'
import { useFeatures } from 'main/hooks/useFeatures'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { Rate } from './components/rate/rate'
import { Grid } from '@mui/material'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

interface PaymentProps {
  isEmpty: boolean
  order: any //IOrder
  setBalanceToBeUsed: (balance: number) => void
  balanceToBeUsed: number
  setUseBalanceProjection: (projection: boolean) => void
  shouldUseProjection: boolean
  confirmOrder: (days?: any) => Promise<void>
  setMethod: (method: 'PIX' | 'SLIP' | 'TERM_BILL') => void
  method: 'PIX' | 'SLIP' | 'TERM_BILL'
  isNewApi?: boolean
  setRate?: (rate: {
    interestRateDays: number
    interestRate: number
    nextDueDate: string
    rateValue: number
    totalValueWithInterestRate: number
  }) => void
  rate?: {
    interestRateDays: number
    interestRate: number
    nextDueDate: string
    rateValue: number
    totalValueWithInterestRate: number
  }
  isDealer?: boolean
  removeAsSoonAsPossibleDays?: any
}

export const Payment = ({
  isEmpty,
  order,
  balanceToBeUsed,
  setBalanceToBeUsed,
  confirmOrder,
  setMethod,
  shouldUseProjection,
  setUseBalanceProjection,
  method,
  setRate,
  rate,
  isDealer,
  isNewApi,
}: PaymentProps) => {
  const { resources } = useAccessProfile({ resource: 'recarga' })
  const { push } = useNotifications()

  const { pathname } = useLocation()
  const isRecharge = !['/cartoes/novo/segunda_confirmacao'].includes(pathname)
  const { features } = useFeatures()

  useEffect(() => {
    if (order?.interestRates?.length === 1 && method === 'TERM_BILL') {
      setRate({
        interestRate: order?.interestRates?.[0]?.rate,
        interestRateDays: order?.interestRates?.[0]?.days,
        nextDueDate: order?.interestRates?.[0]?.nextDueDate,
        rateValue: order?.interestRates?.[0]?.rateValue,
        totalValueWithInterestRate: order?.interestRates?.[0]?.totalValueWithInterestRate,
      })
    }
  }, [order, method])

  useEffect(() => {
    if (method !== 'TERM_BILL') {
      setRate({
        interestRate: 0,
        interestRateDays: 0,
        nextDueDate: '',
        rateValue: 0,
        totalValueWithInterestRate: 0,
      })
    }
  }, [method])

  const notAllowProjection = order?.ticketingBalanceValue > 0
  const [walletUsageError, setWalletUsageError] = useState('')
  const showDaysForCredit = isNewApi && order?.daysForCredit
  if (isEmpty) {
    return (
      <NoItemPaper>
        <Typography variant='h2'>Pagamento</Typography>
        <Divider sx={{ margin: '32px 0' }} />
        <Typography variant='label2'>Aqui estarão as informações e valores do seu pedido.</Typography>
        <br />
        <Typography variant='label2'>Adicione ao menos um item aop carrinho para visualizar.</Typography>
      </NoItemPaper>
    )
  } else
    return (
      <Paper
        sx={(theme: { palette: { grey: any[] } }) => ({ padding: '16px', backgroundColor: theme.palette.grey[50] })}
      >
        <Typography variant='h2'>Pagamento</Typography>
        {features.methodPayments ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: '32px 0 12px' }}
          >
            <Typography style={{ marginBottom: 24 }} variant='subtitle2'>
              Método de pagamento
            </Typography>

            <Box sx={{ display: 'flex', gap: '8px' }}>
              {resources({ item: 'recarga_boleto' }) ? (
                <Grid md={4}>
                  <MethodPayment checked={method === 'SLIP'} name='Boleto' onClick={() => setMethod('SLIP')} />
                </Grid>
              ) : null}
              {isDealer && order?.interestRates?.length ? (
                resources({ item: 'recarga_aprazo' }) ? (
                  <Grid md={4}>
                    <Tooltip
                      title={
                        order?.confirmation?.totalAmountPayable <= order?.financedSalesMinimalValue
                          ? `O pagamento via boleto a prazo só está disponível para pedidos acima de R$ ${order?.financedSalesMinimalValue.toFixed(
                              2,
                            )}. Para pedidos abaixo desse valor, solicitamos que escolha outro método de pagamento.`
                          : null
                      }
                      placement='top'
                    >
                      <MethodPayment
                        blocked={order?.confirmation?.totalAmountPayable <= order?.financedSalesMinimalValue}
                        checked={method === 'TERM_BILL'}
                        name='Boleto a Prazo'
                        onClick={() =>
                          order?.confirmation?.totalAmountPayable >= order?.financedSalesMinimalValue
                            ? !order?.financedSalesPendingPayments
                              ? setMethod('TERM_BILL')
                              : push({
                                  content: order?.financedSalesPendingPaymentsMessage,
                                  type: EMessageType.Warning,
                                })
                            : null
                        }
                      />
                      <div />
                    </Tooltip>
                  </Grid>
                ) : null
              ) : null}

              {resources({ item: 'recarga_pix' }) ? (
                <Grid md={4}>
                  <MethodPayment checked={method === 'PIX'} name='Pix' onClick={() => setMethod('PIX')} />
                </Grid>
              ) : null}
            </Box>
            {method === 'TERM_BILL' ? (
              <Grid
                container
                spacing='8px'
                maxWidth='565px'
                sx={{ display: 'flex', marginTop: '16px', flexWrap: 'wrap' }}
              >
                {order?.interestRates?.map((rates) => {
                  return (
                    <Grid item md={6} xs={6} sm={4} key={rates?.days}>
                      <Rate
                        date={rates?.nextDueDate}
                        day={rates?.days}
                        totalValue={rates?.totalValueWithInterestRate}
                        checked={rate?.interestRateDays === rates?.days}
                        onClick={() =>
                          setRate({
                            interestRate: rates?.rate,
                            interestRateDays: rates?.days,
                            nextDueDate: rates?.nextDueDate,
                            rateValue: rates?.rateValue,
                            totalValueWithInterestRate: rates?.totalValueWithInterestRate,
                          })
                        }
                      />
                    </Grid>
                  )
                })}
                <TextRates>
                  Ao selecionar o método de pagamento à prazo, você terá até a data selecionada para efetuar o pagamento
                  do boleto. <b>Acréscimo de valores referentes à Taxa Administrativa.</b>
                </TextRates>
              </Grid>
            ) : null}
          </Box>
        ) : null}
        {resources({ item: 'recarga_carteiradigital' }) ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '32px 0 12px' }}>
              <Typography variant='subtitle2'>Saldo Carteira digital</Typography>
              <p>
                <small>R$</small> {formatMoney(order?.accountBalance)}
              </p>
            </Box>
            <Typography variant='label2' sx={{ fontWeight: 400, marginBottom: '16px', display: 'block' }}>
              Para usar a Carteira Digital no pagamento deste pedido, digite o valor abaixo.
            </Typography>
            <MaskedTextField
              label='Valor de uso da Carteira Digital'
              placeholder='R$ 00,00'
              value={String(balanceToBeUsed)}
              helperText={walletUsageError}
              id='rechrge_manual_digital_wallet'
              onChange={(value) => {
                setBalanceToBeUsed(Number(value))

                let newValue: number = Number(value) || 0
                if (Number(value) > order?.totalBeforeBalanceUsage) {
                  newValue = order?.totalBeforeBalanceUsage
                  setWalletUsageError(
                    `Você não pode exceder o valor total a ser pago de R$ ${formatMoney(
                      order?.totalBeforeBalanceUsage,
                    )}`,
                  )
                } else if (Number(value) > order?.accountBalance) {
                  newValue = order?.accountBalance <= 0 ? 0 : order?.accountBalance
                  setWalletUsageError(
                    `Você não pode exceder o seu saldo atual de R$ ${formatMoney(order?.accountBalance)}`,
                  )
                }
                setBalanceToBeUsed(newValue)
              }}
              mask={[
                { mask: '' },
                {
                  mask: 'R$ num',
                  lazy: true,
                  blocks: {
                    num: {
                      mask: Number,
                      scale: 2,
                      thousandsSeparator: '.',
                      radix: ',',
                      mapToRadix: ['.'],
                    },
                  },
                },
              ]}
              disabled={order?.accountBalance <= 0 || order?.accountBalance === 0}
            />
          </>
        ) : null}
        <Typography variant='subtitle2' sx={{ marginTop: '32px' }}>
          Resumo da compra
        </Typography>
        <Divider sx={{ margin: '32px 0' }} />
        <ValuesSummary
          values={[
            {
              label: `Valor ${isRecharge ? 'da recarga' : 'do pedido'}`,
              value: order?.orderValue,
              size: 'md',
              hideZero: true,
            },
            {
              label: 'Taxas administrativas',
              value: order?.administrateFee + rate?.rateValue,
              size: 'md',
              hideZero: true,
            },
            { label: 'Taxa de repasse', value: order?.transferFeeValue, size: 'md', hideZero: true },
            features.ticketingBalance && order?.ticketingBalanceValue > 0
              ? {
                  label: 'Conta corrente total',
                  value: order?.ticketingBalanceValue,
                  size: 'md',
                  hideZero: true,
                  isNegative: true,
                  color: 'green',
                }
              : null,
            {
              label: 'Retenção ISS',
              value: order?.issFeeValue,
              size: 'md',
              hideZero: true,
              isNegative: true,
              color: 'green',
            },
            { label: 'Carteira digital', value: Number(balanceToBeUsed), size: 'md', isNegative: true, color: 'green' },
          ]}
        />
        <Divider sx={{ margin: '32px 0' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 600 }}>
          <Typography sx={{ fontWeight: 600 }} id='recharge_manual_payment_total_label'>
            Total a ser pago:{' '}
          </Typography>
          <Typography variant='h4' sx={{ fontWeight: 600 }} id='recharge_manual_payment_total_value'>
            <span style={{ fontWeight: 400 }}>R$ </span>{' '}
            {formatMoney(order?.totalBeforeBalanceUsage - balanceToBeUsed + rate?.rateValue)}
          </Typography>
        </Box>
        {isRecharge && order?.showBalanceProjection ? (
          resources({ item: 'recarga_projecaodesaldo' }) ? (
            <Box
              sx={(theme) => ({
                border: '1px solid ' + theme.palette.primary.main,
                borderRadius: '4px',
                padding: '8px 22px',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                marginTop: '22px',
                '& label': {
                  marginBottom: 0 + '!important',
                  color: theme.palette.primary.main,
                  fontSize: '1rem',
                  width: '100%',
                },
                '& b': {
                  margin: 'auto',
                  whiteSpace: 'wrap',
                },
                'input, label': notAllowProjection
                  ? {
                      cursor: 'not-allowed',
                    }
                  : {
                      cursor: 'pointer',
                    },
              })}
            >
              <CheckboxField
                label={<b>Quero fazer a projeção de saldo</b>}
                onChange={(e) =>
                  notAllowProjection
                    ? push({
                        content:
                          'Não é possível realizar a projeção de saldo em pedidos que utilizam saldo da conta corrente bilhetagem',
                        type: EMessageType.Error,
                      })
                    : setUseBalanceProjection(e.target.checked)
                }
                value={notAllowProjection ? false : shouldUseProjection}
                id='recharge_manual_value_projection'
              />
            </Box>
          ) : null
        ) : null}
        <Button
          id='recharge_manual_confirm_order'
          variant='contained'
          color='success'
          fullWidth
          sx={{ color: 'white', marginTop: '32px' }}
          onClick={async () => {
            confirmOrder()
          }}
          disabled={(!method && features.methodPayments) || (method === 'TERM_BILL' && !rate.interestRateDays)}
        >
          <Tooltip
            title={
              method === 'TERM_BILL' && !rate?.interestRate
                ? 'Para realizar uma compra a prazo, é necessário informar os dias para pagamento'
                : null
            }
            placement='top'
          >
            <p style={{ width: '100%', height: '100%', paddingTop: '4px' }}>Concluir pedido</p>
          </Tooltip>
        </Button>
      </Paper>
    )
}
