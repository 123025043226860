import { useState, useEffect } from 'react'
import { Container, Col, useScreenClass } from 'react-grid-system'
import { Row, Wrapper } from './styles'
import {
  Button,
  Pagination,
  Search,
  Table,
  // OrderIcon,
  DropdownButton,
} from 'presentation/components'
import Filter from './filter'
import paginatorMethods from '@/utils/paginatorMethods'
import { ButtonWrapper } from '../style'
import { formatMoney } from '@/utils/functionUtils'
import { useExtract } from './hooks/useExtract'

const ExtractTable = ({ employeeId, employeeName, visible }) => {
  const [filter, setFilter] = useState('')

  const { data, isLoading, downloadExtract, isDownloading } = useExtract({ employeeId, filter, employeeName, visible })

  const [newData, setNewData] = useState(data)
  const extractsData = newData

  const [showFilter, setShowFilter] = useState(false)
  const screenClass = useScreenClass()

  const [paginator, setPaginator] = useState({
    page: 1,
    pageSize: 10,
    query: {},
    totalItens: 0,
    totalPages: 0,
    doRequest: true,
  })

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        page: paginator.page,
        totalItens: data?.length,
        totalPages: Math.ceil(data?.length / paginator.pageSize),
      }),
    )
    // eslint-disable-next-line
  }, [data, paginator.pageSize])

  useEffect(() => {
    setNewData(() => {
      const endSlice = paginator.pageSize * paginator.page
      const initSlice = endSlice - paginator.pageSize
      if (!data) return []
      return data?.slice(initSlice, endSlice)
    })
  }, [paginator, data])

  function generateTableHeader() {
    return (
      <Table.Tr>
        <Table.Th>
          <Wrapper>Data</Wrapper>
        </Table.Th>
        <Table.Th>Hora</Table.Th>
        <Table.Th>Linha</Table.Th>
        <Table.Th>
          <Wrapper>Valor</Wrapper>
        </Table.Th>
        <Table.Th>Tarifa</Table.Th>
      </Table.Tr>
    )
  }

  function generateTableRow() {
    return extractsData?.map((extract, index) => {
      const date = new Date(extract?.usageDate).toLocaleString().split(',')
      const hour = date?.[1].split(':')

      return (
        <Table.Tr style={{ cursor: 'pointer' }} key={'blocked_card_' + index}>
          <Table.Td>{date?.[0]}</Table.Td>
          <Table.Td>{`${hour?.[0]}:${hour?.[1]}:${hour?.[2]}`}</Table.Td>
          <Table.Td>{extract?.busRoute}</Table.Td>
          <Table.Td>R$ {formatMoney(extract?.value)}</Table.Td>
          <Table.Td>{extract?.feeDescription}</Table.Td>
        </Table.Tr>
      )
    })
  }

  return (
    <Container style={{ padding: '16px', maxWidth: '100%' }}>
      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'}>
        <Col xs={12} sm={12} md={7} lg={6} xl={5}>
          <Search
            search={(value) => {
              if (!value) {
                return setNewData(data?.slice(0, paginator.pageSize))
              }
              if (!extractsData) return
              setNewData(extractsData.filter((d) => d.feeDescription.toLowerCase().includes(value.toLowerCase())))
            }}
            placeholder='Buscar na tabela abaixo ...'
          />
        </Col>
        <Col
          xs='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '32px' : '0',
          }}
        >
          <Button variant='outlined-action' icon='filter' onClick={() => setShowFilter(true)}>
            Filtrar
          </Button>
        </Col>
        <Col sm={12} md={6} lg={6} xl='content'>
          <ButtonWrapper>
            <DropdownButton
              arrow
              icon='download'
              title='Baixar Extrato'
              loading={isDownloading}
              style={{ minWidth: '206px' }}
            >
              <DropdownButton.Item
                onClick={(e) => {
                  downloadExtract()
                }}
              >
                PDF
              </DropdownButton.Item>
            </DropdownButton>
          </ButtonWrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table loading={isLoading}>
            <thead>{generateTableHeader()}</thead>
            <tbody>{generateTableRow()}</tbody>
          </Table>
        </Col>
      </Row>
      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
        <Col xs={12} md='content' style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination.ResultAmount
            totalItems={paginator.totalItens}
            setPageSize={(newPageSize) => setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))}
          />
        </Col>
        <Col
          xs='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '16px' : '0',
          }}
        >
          <Pagination
            paginator={paginator}
            setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
          />
        </Col>
      </Row>
      {showFilter && (
        <Filter
          actualFilters={paginator.query}
          open={showFilter}
          cancel={() => setShowFilter(false)}
          filter={(queryObj) => {
            const queryObjFormat = {
              UsageDate: `${queryObj.startDate}><${queryObj.endDate}`,
            }
            setFilter(
              `?StartDate=${queryObj.startDate}T00:00:00-03:00&${
                queryObj.endDate ? `EndDate=${queryObj.endDate}T23:59:59-03:00` : ''
              }`,
            )
            setPaginator(paginatorMethods.setFilters(paginator, queryObjFormat))
            setShowFilter(false)
          }}
          resetFiltersWithID={() => setFilter('')}
        />
      )}
    </Container>
  )
}

export default ExtractTable
