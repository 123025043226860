import { Content } from 'presentation/components'
import { EmployeeRegister } from './employeeRegister'

const EmployeeForm = () => {
  return (
    <Content>
      <EmployeeRegister />
    </Content>
  )
}

export default EmployeeForm
