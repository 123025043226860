import { useNavigate } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { faCircleInfo, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { Menu } from 'presentation/components/atoms/menu'
import { MenuItem } from 'presentation/components/atoms/menuItem'
import { Divider } from 'presentation/components/atoms/divider'
import { Icon } from 'presentation/components/atoms/Icon'
import { StyledNavLink } from './styles'
import { Box } from 'presentation/components/atoms/box'
import { Typography } from 'presentation/components/atoms/typography'
import { Tooltip } from 'presentation/components/atoms/tooltip'
import { useAuth } from '@/main/hooks'
import { AccountPersistentStore } from '@/main/store'
import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import { useEffect } from 'react'

export const UserMenu = ({ destinations = [], anchorEl, id, open, onClose, onClick, hideTerm }) => {
  const { user, company } = useAtomValue(AccountPersistentStore)
  const navigate = useNavigate()
  const auth = useAuth(navigate)

  const { data, refetch } = useQuery({
    queryKey: [''],
    queryFn: () => api.get('rh-bff/fileuploads/donwloadTermsConditions'),
    enabled: false,
  })

  useEffect(() => {
    if (data?.data) window.open(data.data, 'new_tab')
  }, [data])

  const renderUserInfo = () => {
    const renderText = (text, variant) => (
      <Tooltip title={text}>
        <Typography
          variant={variant}
          component='div'
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {text}
        </Typography>
      </Tooltip>
    )

    return [
      <Box
        key={'menuUserInfo'}
        onClick={(e) => e.stopPropagation()}
        sx={{
          padding: '16px',
          display: { md: 'none' },
        }}
      >
        {renderText(user?.name, 'h4')}
        {renderText(company?.name, 'body')}
      </Box>,
      <Divider key={'menuUserInfoDivider'} light sx={{ display: { md: 'none' } }} />,
    ]
  }

  return (
    <Menu id={id} open={open} anchorEl={anchorEl} onClose={onClose} onClick={onClick}>
      {renderUserInfo()}
      {destinations.map((destination, index) => {
        return (
          <StyledNavLink key={`${index}_userMenuItem`} to={destination.pathname}>
            <MenuItem>
              <Icon icon={destination.icon} />
              {destination.displayName}
            </MenuItem>
          </StyledNavLink>
        )
      })}

      <Divider light />
      {hideTerm ? null : (
        <MenuItem sx={(theme) => ({ color: theme.palette.info.main })} onClick={() => refetch()}>
          <Icon icon={faCircleInfo} />
          Termos e condições
        </MenuItem>
      )}
      <MenuItem sx={(theme) => ({ color: theme.palette.error.main })} onClick={() => auth.logout()}>
        <Icon icon={faRightFromBracket} />
        Sair
      </MenuItem>
    </Menu>
  )
}
