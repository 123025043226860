import { useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

type ApiAddressResponse = {
  cep?: string
  logradouro?: string
  complemento?: string
  unidade?: string
  bairro?: string
  localidade?: string
  uf?: string
  estado?: string
  regiao?: string
  ibge?: string
  gia?: number
  ddd?: number
  siafi?: number
  erro?: string
}

export const useGetAddressByCep = () => {
  const getAddress = useMutation<AxiosResponse<ApiAddressResponse>, any, { cep: string }>({
    mutationKey: ['getAddressByCep'],
    mutationFn: ({ cep }) => axios.get(`https://viacep.com.br/ws/${cep}/json/`),
    retry: false,
  })

  return {
    getAddress,
  }
}
