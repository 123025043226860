import { Grid, MaskedTextField, Select, TextField, Typography } from '@stationkim/front-ui'
import { Controller } from 'react-hook-form'
import { EmployeeFormFragmentSkeleton } from './employeeFormFragmentSkeleton'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'
import { useDepartmentsOptions } from '../../../hooks/useDepartmentsOptions'
import { useWorkingPlacesOptions } from '../../../hooks/useWorkingPlacesOptions'
import { employeeFormRules } from '../utils/employeeFormRules'
import { EmployeeFormFragment } from '../employeeForm'

interface ProfessionalDataProps extends EmployeeFormFragment {}

export const ProfessionalData = ({ form, blocked, isLoading, id }: ProfessionalDataProps) => {
  const { control } = form
  const account = useAtomValue(AccountPersistentStore)
  const { departmentOptions, isLoading: isLoadingDepartments } = useDepartmentsOptions({
    companyId: account.company.id,
  })
  const { workingPlacesOptions, isLoading: isLoadingWorkingPlaces } = useWorkingPlacesOptions({
    companyId: account.company.id,
  })
  if (isLoading) return <EmployeeFormFragmentSkeleton />
  return (
    <Grid container spacing='16px'>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='registration'
          rules={employeeFormRules.registration}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Matrícula'
              placeholder='00000000'
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='departmentId'
          rules={employeeFormRules.departmentId}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <Select
              id={`${id}_${name}`}
              variant='outlined'
              label='Departamento'
              options={departmentOptions}
              blocked={blocked || isLoadingDepartments}
              placeholder='Selecione o departamento'
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
              isLoadingOptions={isLoadingDepartments}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='role'
          rules={employeeFormRules.role}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Cargo'
              placeholder='Cargo'
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={0} lg={3} sx={{ padding: '0 !important' }} />
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='workingPlace'
          rules={employeeFormRules.workingPlace}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <Select
              id={`${id}_${name}`}
              variant='outlined'
              label='Endereço do Local de Trabalho'
              options={workingPlacesOptions}
              blocked={blocked || isLoadingWorkingPlaces}
              placeholder='Selecione o local'
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
              isLoadingOptions={isLoadingWorkingPlaces}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='cellphoneNumber'
          rules={employeeFormRules.cellphoneNumber}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <MaskedTextField
              id={`${id}_${name}`}
              label='Telefone'
              blocked={blocked}
              placeholder='(00) 0000-0000'
              onChange={onChange}
              unmask
              mask={{ mask: '(00) 0000-0000' }}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='secondCellphoneNumber'
          rules={employeeFormRules.secondCellphoneNumber}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <MaskedTextField
              id={`${id}_${name}`}
              label='Celular'
              blocked={blocked}
              placeholder='(00) 00000-0000'
              onChange={onChange}
              unmask
              mask={{ mask: '(00) 00000-0000' }}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={0} lg={3} sx={{ padding: '0 !important' }} />

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='email'
          rules={employeeFormRules.email}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='E-mail'
              placeholder='colaborador@empresa.com.br'
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='workingDays'
          rules={employeeFormRules.workingDays}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <MaskedTextField
              id={`${id}_${name}`}
              label='Dias a Trabalhar no Mês'
              placeholder='00'
              mask={{ mask: '00' }}
              unmask
              blocked={blocked}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
