import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient()
  const notify = useNotifications()

  const { mutate: deleteRow, isLoading } = useMutation({
    mutationFn: async (rowId: string) => api.delete(`rh-bff/brokerapi/importacaoItem/${rowId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['brokerImportResults'] })
      notify.push({ content: 'Registro removido', type: EMessageType.Success })
    },
  })
  return {
    deleteRow,
    isLoading,
  }
}
