import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import paginatorMethods from '@/utils/paginatorMethods'

import { getEmployeesRequest } from '@/store/modules/employees/actions'

export const useEmployees = ({ paginator }) => {
  const storeData = useSelector((state) => state.employees)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleCloseModal = (e, didSomething) => {
    setOpen(false)
    if (didSomething) dispatch(getEmployeesRequest(paginatorMethods.buildQuery(paginator)))
  }

  useEffect(() => {
    paginator.doRequest && dispatch(getEmployeesRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  const employeesData = useMemo(() => storeData?.data?.data || [], [storeData])
  const isLoading = storeData.loading
  const employeesPagination = {
    pageNumber: storeData.data?.pageNumber,
    totalItens: storeData.data?.totalItens,
    totalPages: storeData.data?.totalPages,
    pageSize: storeData.data?.pageSize,
  }

  return {
    employeesData,
    isLoading,
    employeesPagination,
    failure: storeData?.failure,
    open,
    handleOpen,
    handleCloseModal,
  }
}
