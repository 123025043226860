import { useEffect, useState } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Icon,
  Typography,
  RequiredMark,
} from '@stationkim/front-ui'
import { EEmployeeGender, EFormMode } from '@/main/enums'
import { PersonalData } from './components/personalData'
import { ProfessionalData } from './components/professionalData'
import { ConfirmEditionCancelDialog } from './components/confirmEditionCancelDialog'
import { AddressData } from './components/addressData'
import { IUseEmployeeReturn } from '../../hooks/useEmployee'
import { IEmployeePayload } from '../../hooks/useEmployeeMutation'

export type TEmployeeForm = {
  cpf: string
  birthDate: string
  fullname: string
  rg: string
  issueDate: string
  issuingBody: string
  state: string
  gender: EEmployeeGender | string
  motherName: string
  registration: string
  departmentId: string
  role: string
  workingPlace: string
  cellphoneNumber: string
  secondCellphoneNumber: string
  email: string
  workingDays: string
  cep: string
  livingState: string
  city: string
  addressDescript: string
  numberAddress: string
  district: string
  complement: string
}

export interface EmployeeFormFragment {
  form: UseFormReturn<TEmployeeForm>
  blocked?: boolean
  isLoading?: boolean
  id?: string
}

type TAccordionsState = {
  personalData: boolean
  professionalData: boolean
  addressData: boolean
}

const accordionsStateDefaultValue: TAccordionsState = {
  addressData: true,
  personalData: true,
  professionalData: true,
}

const defaultValues = {
  birthDate: '',
  cpf: '',
  fullname: '',
  gender: '',
  issueDate: '',
  issuingBody: '',
  motherName: '',
  rg: '',
  state: '',

  registration: '',
  cellphoneNumber: '',
  departmentId: '',
  email: '',
  role: '',
  secondCellphoneNumber: '',
  workingDays: '',
  workingPlace: '',
}

export interface EmployeeFormProps {
  employee: IUseEmployeeReturn['employee']
  mode: EFormMode
  setMode: React.Dispatch<React.SetStateAction<EFormMode>>
  setLock: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  onEdit: (data: IEmployeePayload) => void
  onCreate: (data: IEmployeePayload) => void
}

const cleanObject = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (typeof value === 'object' && value !== null) cleanObject(value)
    else if (!value) delete obj[key]
  })
  return obj
}

const baseId = 'employeeForm'

export const EmployeeForm = ({ employee, mode, setMode, setLock, isLoading, onCreate, onEdit }: EmployeeFormProps) => {
  const navigate = useNavigate()

  const isViewing = mode === EFormMode.View
  const isEditing = mode === EFormMode.Edit
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false)
  const [accordionsState, setAccordionsState] = useState<TAccordionsState>(accordionsStateDefaultValue)

  const form = useForm<TEmployeeForm>({ defaultValues })

  useEffect(() => {
    if (employee && mode !== EFormMode.Add) form.reset(employee)
  }, [employee, form, mode])

  useEffect(() => {
    setLock(form.formState.isDirty)
  }, [form.formState.isDirty, setLock])

  const onSubmit: SubmitHandler<TEmployeeForm> = (data) => {
    const payload = cleanObject({
      fullname: data.fullname,
      birthDate: data.birthDate,
      cpf: data.cpf,
      gender: Number(data.gender),
      issueDate: data.issueDate,
      issuingBody: data.issuingBody,
      motherName: data.motherName,
      rg: data.rg,
      state: data.state,
      professionalData: {
        registration: data.registration,
        cellphoneNumber: data.cellphoneNumber,
        departmentId: data.departmentId,
        email: data.email,
        role: data.role,
        workingPlace: data.workingPlace,
        workingDays: Number(data.workingDays),
        secondCellphoneNumber: data.secondCellphoneNumber,
      },
      address: {
        addressDescript: data.addressDescript,
        cep: data.cep,
        city: data.city,
        complement: data.complement,
        district: data.district,
        numberAddress: data.numberAddress,
        state: data.livingState,
      },
    })

    if (mode === EFormMode.Add) onCreate(payload as IEmployeePayload)
    else if (mode === EFormMode.Edit) onEdit(payload as IEmployeePayload)
  }
  const onSubmitError: SubmitErrorHandler<TEmployeeForm> = () => setAccordionsState(accordionsStateDefaultValue)

  const handleCancel = () => {
    if (isEditing) {
      if (form.formState.isDirty) setIsCancelConfirmOpen(true)
      else setMode(EFormMode.View)
    } else navigate('/colaboradores')
  }

  const handleAccordionChange = (accordion: keyof TAccordionsState, value: boolean) => {
    setAccordionsState((state) => ({ ...state, [accordion]: value }))
  }

  return (
    <>
      <form id='employee-form' style={{ padding: '16px' }} onSubmit={form.handleSubmit(onSubmit, onSubmitError)}>
        <Accordion
          expanded={accordionsState.personalData}
          onChange={(_, value) => handleAccordionChange('personalData', value)}
        >
          <AccordionSummary expandIcon={<Icon icon={faChevronDown} />}>
            <Typography variant='h2'>
              Dados Pessoais <RequiredMark />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PersonalData id={baseId} form={form} blocked={isViewing} isLoading={isLoading} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={accordionsState.professionalData}
          onChange={(_, value) => handleAccordionChange('professionalData', value)}
        >
          <AccordionSummary expandIcon={<Icon icon={faChevronDown} />}>
            <Typography variant='h2'>
              Dados Profissionais <RequiredMark />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProfessionalData id={baseId} form={form} blocked={isViewing} isLoading={isLoading} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={accordionsState.addressData}
          onChange={(_, value) => handleAccordionChange('addressData', value)}
        >
          <AccordionSummary expandIcon={<Icon icon={faChevronDown} />}>
            <Typography variant='h2'>Endereço</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddressData id={baseId} form={form} blocked={isViewing} isLoading={isLoading} />
          </AccordionDetails>
        </Accordion>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
          <Button variant='outlined' onClick={handleCancel}>
            {isEditing ? 'Cancelar' : 'Voltar'}
          </Button>
          <Button
            variant='contained'
            form='employee-form'
            type='submit'
            sx={{
              width: !isViewing ? 'auto' : '0',
              minWidth: 'initial',
              padding: !isViewing ? '7px 21px' : '0',
              overflow: 'hidden',
              transition: '.25s all linear',
            }}
            disabled={isLoading}
          >
            {isEditing ? 'Salvar' : 'Cadastrar'}
          </Button>
        </Box>
      </form>
      <ConfirmEditionCancelDialog
        open={isCancelConfirmOpen}
        onCancel={() => setIsCancelConfirmOpen(false)}
        onConfirm={() => {
          form.reset()
          setMode(EFormMode.View)
          setIsCancelConfirmOpen(false)
        }}
      />
    </>
  )
}
