import { useState } from 'react'
import { useAtomValue } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { AutoTable, Box, Button, Grid, Icon, Search, TableFooterPagination, usePagination } from '@stationkim/front-ui'
import { TBenefit, useEmployeeBenefits } from './hooks/useEmployeeBenefits'
import { employeeIdAtom } from '@/main/store'
import { columnsConfig } from './utils/columnsConfig'
import { BenefitBindDialog } from './benefitBindDialog/benefitBindDialog'
import { useBenefitMutation } from './hooks/useBenefitMutation'
import { EEmployeeBenefitStatus, EFormMode } from '@/main/enums'

const paginationAtom = atomWithHash('employeeBenefitPagination', {})
type TBenefitDialogUsage = {
  open: boolean
  benefit: TBenefit | null
  mode: EFormMode
}

const defaultDialogUsage: TBenefitDialogUsage = {
  benefit: null,
  mode: EFormMode.Add,
  open: false,
}
export const Benefits = () => {
  const employeeId = useAtomValue(employeeIdAtom)
  const [dialogUsage, setDialogUsage] = useState<TBenefitDialogUsage>(defaultDialogUsage)
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom: paginationAtom })
  const { benefits, isLoading } = useEmployeeBenefits({ pagination, employeeId })
  const { changeStatus } = useBenefitMutation()

  const onEdit = (benefit: TBenefit) => {
    setDialogUsage({
      benefit,
      mode: EFormMode.Edit,
      open: true,
    })
  }
  const onStatusChange = (benefit: TBenefit) => {
    changeStatus.mutate({
      benefitId: benefit.benefitId,
      status:
        benefit.status === EEmployeeBenefitStatus.Active
          ? EEmployeeBenefitStatus.Inactive
          : EEmployeeBenefitStatus.Active,
    })
  }
  const columns = columnsConfig({ onEdit, onStatusChange })

  return (
    <Box sx={{ padding: '16px' }}>
      <Grid container spacing='16px' justifyContent='space-between'>
        <Grid item xs sm={6} md={5} lg={4}>
          <Search placeholder='Buscar na tabela abaixo' />
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            variant='contained'
            startIcon={<Icon icon={faPlusCircle} />}
            fullWidth={true}
            id='employeeBenefit_button_new'
            onClick={() => setDialogUsage({ ...defaultDialogUsage, open: true })}
          >
            Novo Benefício
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable
            columns={columns}
            rows={benefits.data}
            isLoading={isLoading}
            onRowClick={({ row }) => setDialogUsage({ benefit: row, mode: EFormMode.View, open: true })}
          />
        </Grid>

        <Grid item xs>
          <TableFooterPagination
            page={benefits.pageNumber}
            rowsPerPage={benefits.pageSize}
            totalPages={benefits.totalPages}
            totalItems={benefits.totalItens}
            onPageChange={(_e, targetPage) => goToPage(Number(targetPage))}
            onRowsPerPageChange={(e) => setPageSize(Number(e.target))}
          />
        </Grid>
      </Grid>
      {dialogUsage.open && (
        <BenefitBindDialog
          onClose={() => setDialogUsage(defaultDialogUsage)}
          existentBenefitsBinds={benefits.data}
          initialMode={dialogUsage.mode}
          initialBenefitData={dialogUsage.benefit}
        />
      )}
    </Box>
  )
}
