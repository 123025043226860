import { Icon } from '@stationkim/front-ui'
import { RateChipContainer, RateChipContent, RemoveButton } from './styles'
import { faX } from '@fortawesome/free-solid-svg-icons'

interface RateChipProps {
  label: string
  value: string
  id: string
  onClick?: (value: string) => void
}

export const RateChip = ({ label, value, id, onClick }: RateChipProps) => {
  return (
    <RateChipContainer>
      <label>{label}</label>
      <RateChipContent>
        <p id={id}>{value}</p>
        {onClick && (
          <RemoveButton type='button' onClick={() => onClick(value)}>
            <Icon icon={faX} />
          </RemoveButton>
        )}
      </RateChipContent>
    </RateChipContainer>
  )
}
