import { useState, useEffect } from 'react'

import api from '@/infra/api'

import { useNotifications } from 'main/hooks/useNotifications'

import { IOrder, infosData } from './utils/infosData'
import { IDetails, detailsData } from './utils/detailsData'
import { errorHandler } from '@/main/utils/functions/errorHandler'
import { useLocation } from 'react-router-dom'

interface IUseRequestInfos {
  orderId: string
}

export const useRequestInfos = ({ orderId }: IUseRequestInfos) => {
  const { push } = useNotifications()
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const [data, setData] = useState<IOrder>({} as IOrder)
  const [details, setDataDetails] = useState<IDetails[]>([] as IDetails[])

  const type = () => {
    switch (location.pathname) {
      case '/cartoes/novo/segunda_confirmacao': {
        return 'cards/new/second/confirmation'
      }
      case '/recargas/novo_pedido_importacao/confirmacao':
      case '/recargas/novo_pedido_manual/confirmacao': {
        return 'recharge/new/confirmation'
      }
    }
  }

  const requestInfo = async () => {
    setLoading(true)
    try {
      const response = await api.get(`rh-bff/${type()}/${orderId}`)

      const warn = response?.data?.inelegibleEmploees?.length

      if (warn && location.pathname === '/cartoes/novo/segunda_confirmacao') {
        const names = response?.data?.inelegibleEmploees?.map((employee) => employee.fullname)?.join(', ')

        setData(infosData({ infos: response?.data }))
        setDataDetails(detailsData({ infos: response?.data, orderId }))

        throw Error(
          `Colaborador ${names}, não está apto na bilhetagem para solicitar cartão, ${
            warn?.length > 1 ? 'eles foram removidos' : 'ele foi removido'
          } do pedido.`,
        )
      }

      setData(infosData({ infos: response?.data }))
      setDataDetails(detailsData({ infos: response?.data, orderId }))
    } catch (error) {
      push(errorHandler({ error: error?.message || error?.response?.data?.errors }))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (orderId) {
      requestInfo()
    }
    // eslint-disable-next-line
  }, [])

  return {
    details,
    data,
    isLoading: loading,
  }
}
