import { UseFormReturn } from 'react-hook-form'
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { Button, Form, FormFileUpload, FormSelect, Grid, Icon, Skeleton, Typography } from '@stationkim/front-ui'
import { download } from '@/utils/functionUtils'
import { NewOrderContainer } from '../styles'
import { IUseBrokerOrderReturn, LayoutOption } from '../hooks/shared'

interface NewImportProps {
  form: UseFormReturn<any>
  isLoading: IUseBrokerOrderReturn['isLoading']
  operator: LayoutOption
  options: LayoutOption[]
}

export const NewImport = ({ form, isLoading, operator, options }: NewImportProps) => {
  const downloadFile = async ({ operator }) => {
    const response = await axios({
      url: operator.modelLink,
      method: 'GET',
      responseType: 'blob',
    })
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
    download(fileUrl, `Modelo de arquivo para pedido de recarga broker - ${operator.label}.xlsx`)
  }
  return (
    <NewOrderContainer>
      <Typography variant='body2' sx={{ marginBottom: '16px' }}>
        Solicite uma recarga através do arquivo de importação. Baixe um modelo de arquivo, preencha com as informações
        solicitadas respeitando os campos.
        <br /> Em seguida salve o arquivo e faça a importação.
      </Typography>
      <Form id='orderImportForm' form={form} onSubmit={() => {}} gridProps={{ spacing: '16px' }}>
        {isLoading.options ? (
          <Grid item xs={12} sm={6} sx={{ marginTop: '32px' }}>
            <Skeleton variant='rounded' height='48px' width='100%' fullWidth />
          </Grid>
        ) : (
          <FormSelect
            variant='outlined'
            label='Selecione o modelo'
            placeholder='Selecione...'
            name='operatorId'
            options={options}
            getOptionValue={(option) => option.id}
            rules={{ required: 'É preciso selecionar uma operadora' }}
            gridProps={{ xs: 12, sm: 6, md: 5, lg: 3 }}
          />
        )}
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            type='button'
            variant='contained'
            sx={{ marginTop: { xs: 0, sm: '32px' } }}
            startIcon={<Icon icon={faArrowRightToBracket} sx={{ transform: 'rotate(90deg)' }} />}
            onClick={() => downloadFile({ operator })}
            fullWidth
            disabled={!operator}
          >
            Baixar Modelo Excel (XLSX)
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <FormFileUpload
            name='files'
            rules={{ required: 'Para prosseguir é preciso anexar o arquivo preenchido' }}
            accept={{ accept: ['.xlsx'] }}
            maxSize={3150000}
          />
        </Grid>
      </Form>
    </NewOrderContainer>
  )
}
