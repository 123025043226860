import React, { useEffect, useMemo, useState } from 'react'
import { Content, Background, Panel, ModalV2 } from 'presentation/components'

import { HasOderText } from './styles'
import { FORM_MODE } from '@/utils/constants'
import paginatorMethods from '@/utils/paginatorMethods'

import { IoIosInformationCircleOutline } from 'react-icons/io'
import EmployeeTable from './components/employeeTable/employeeTable'
import AssignDaysModal from './components/assignDaysModal/assignDaysModal'
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  LoadingButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from 'presentation/components/atoms'
import { Search } from 'presentation/components/molecules'
import { PageHeader } from 'presentation/components'
import { faChevronDown, faFilter, faUpload, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons'
import { EMPLOYEE_OPERATOR_INTEGRATION, EMPLOYEE_STATUS } from 'utils/constants'
import { Filter } from 'presentation/components/organisms'
import useService from 'main/hooks/useService'
import { getEmployeesFilterData } from 'services/employeesServices'
import _ from 'lodash'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { useEmployees } from '../hooks/useEmployees'
import { useEmployee } from '../hooks/useEmployee'
import { useNotification } from 'main/hooks/useNotification'
import { usePaginationOld } from 'main/hooks/usePaginationOld'
import { useNavigate } from 'react-router-dom'
import { AutoTable } from '@stationkim/front-ui'
import { employeesTableColumnsConfig } from './utils/employeesTableColumnsConfig'
import { EFormMode } from '@/main/enums'

const Employees = () => {
  const { resources } = useAccessProfile({ resource: 'colaborador' })

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [reportMenuRef, setReportMenuRef] = useState(null)
  const navigate = useNavigate()
  const query = {
    Sort: 'fullname',
    status: 1,
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const { employeesData, isLoading, employeesPagination, failure, handleCloseModal, handleOpen, open } = useEmployees({
    paginator,
  })
  const {
    changeEmployeeStatus,
    changingEmployeeStatus,
    employeeToChangeStatus,
    isHasOrderModalVisible,
    isEmployeeActive,
    setEmployeeToChangeStatus,
    setHasOrderModalVisible,
    requestEmployeesReport,
    loading,
  } = useEmployee({ paginator })
  const { errorNotification } = useNotification()

  const [filterData, setFilterData] = useState({
    benefits: [],
    operatorsCards: [],
    departments: [],
  })

  const [fetchFilterValuesState, fetchFilterValuesRequest] = useService(getEmployeesFilterData, {
    silent: false,
    onCompleted: (res) => {
      const rawFilterValues = res.data

      const cards = rawFilterValues.benefitTypes.reduce((cards, operator) => {
        return [...cards, ...operator.benefits]
      }, [])

      setFilterData({
        benefitTypes: rawFilterValues.benefitTypes,
        operatorsCards: cards,
        departments: rawFilterValues.departments,
      })
    },
  })

  useEffect(() => {
    fetchFilterValuesRequest()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (failure !== null) {
      const errorMessage = failure
      errorNotification(errorMessage)
    }

    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        page: employeesPagination.pageNumber,
        totalItens: employeesPagination.totalItens,
        totalPages: employeesPagination.totalPages,
      }),
    )
    // eslint-disable-next-line
  }, [employeesData])

  function goToManualRegistration() {
    navigate('/colaboradores/cadastro', {
      state: { formMode: EFormMode.Add },
    })
  }

  function goToImport() {
    navigate('/colaboradores/importacao')
  }

  const handleChangePagination = (event, value) => {
    setPaginator(paginatorMethods.setPage(paginator, value))
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginator(paginatorMethods.setPageSize(paginator, event.target.value))
  }
  const columns = useMemo(
    () =>
      employeesTableColumnsConfig({
        order: (field, direction) => setPaginator(paginatorMethods.setOrder(paginator, field, direction)),
        onEdit: setEmployeeToChangeStatus,
      }),
    [],
  )

  return (
    <>
      <Content>
        <Panel style={{ padding: '16px' }}>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid item xs={4}>
              <PageHeader borderless>Colaboradores</PageHeader>
            </Grid>
          </Grid>
          <Divider
            sx={{
              width: '150%',
              marginLeft: '-32px',
              marginBottom: '16px',
            }}
          />

          <Grid container alignItems='center' columnSpacing='16px' rowSpacing='16px'>
            <Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
              <Search
                onSearch={(value) =>
                  setPaginator(
                    paginatorMethods.setFilters(paginator, {
                      filterValue: value,
                      filterProps: 'Registration,DepartmentName,Fullname,Status',
                    }),
                  )
                }
                placeholder='Buscar na tabela abaixo ...'
              />
            </Grid>
            <Grid item xs={6} sm='auto'>
              <Button
                startIcon={<Icon icon={faFilter} sx={{ transform: 'scaleX(-1)' }} />}
                variant='outlined'
                fullWidth
                onClick={() => setIsFilterOpen(true)}
                id='employees_button_filter'
              >
                Filtrar
              </Button>
            </Grid>
            <Grid item xs={6} sm='auto'>
              <Box>
                <LoadingButton
                  endIcon={<Icon icon={faChevronDown} />}
                  loading={loading}
                  variant='outlined'
                  fullWidth
                  onClick={(e) => setReportMenuRef(e.currentTarget)}
                  id='employees_button_reportsDropdown'
                >
                  Relatórios
                </LoadingButton>
                {
                  <Menu
                    anchorEl={reportMenuRef}
                    open={Boolean(reportMenuRef)}
                    onClose={() => {
                      setReportMenuRef(null)
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setReportMenuRef(null)
                    }}
                  >
                    <MenuItem
                      id='employees_dropdownItem_downloadPdf'
                      onClick={(e) => {
                        requestEmployeesReport()
                      }}
                    >
                      Baixar PDF
                    </MenuItem>
                  </Menu>
                }
              </Box>
            </Grid>
            <Grid
              container
              item
              columnSpacing='16px'
              rowSpacing='16px'
              xs={12}
              md={12}
              lg
              sx={{ marginLeft: { lg: 'auto' } }}
            >
              {resources({ item: 'colaborador_cadastrar' }) ? (
                <Grid item xs={12} md={4} lg>
                  <Button
                    startIcon={<Icon icon={faUserPlus} sx={{ transform: 'scaleX(-1)' }} />}
                    onClick={goToManualRegistration}
                    variant='contained'
                    fullWidth
                    id='employees_button_add'
                  >
                    Adicionar Colaborador
                  </Button>
                </Grid>
              ) : null}
              {resources({ item: 'colaborador_diasemmassa' }) ? (
                <Grid item xs={6} md={4} lg>
                  <Button
                    startIcon={<Icon icon={faCalendarPlus} />}
                    onClick={() => handleOpen()}
                    variant='contained'
                    fullWidth
                    id='employees_button_bulkDays'
                  >
                    Inserir dias em massa
                  </Button>
                </Grid>
              ) : null}
              {resources({ item: 'colaborador_importacao' }) ? (
                <Grid item xs={6} md={4} lg>
                  <Button
                    id='employees_button_import'
                    startIcon={<Icon icon={faUpload} />}
                    onClick={goToImport}
                    variant='contained'
                    fullWidth
                  >
                    Importar Colaborador
                  </Button>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <AutoTable
                columns={columns}
                rows={employeesData}
                link={({ row }) => `/colaboradores/visualizar#employeeId="${row.employeeId}"`}
                isLoading={isLoading}
              />
            </Grid>
            <Divider
              sx={{
                width: '150%',
                marginTop: '16px',
                marginLeft: '-32px',
              }}
            />
            <Grid
              container
              item
              xs={12}
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              rowSpacing='16px'
              alignItems='center'
            >
              <Grid item xs='auto' sm={6}>
                <TableContainer>
                  <Table>
                    <TableFooter style={{ float: 'left' }}>
                      <TableRow>
                        <TablePagination
                          component='div'
                          rowsPerPageOptions={[10, 20, 25, 50, 100]}
                          count={paginator.totalPages}
                          onPageChange={() => null}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage='Exibir'
                          labelDisplayedRows={() => `resultados de ${paginator.totalItens || 0}`}
                          rowsPerPage={paginator.pageSize}
                          nextIconButtonProps={{ style: { display: 'none' } }}
                          backIconButtonProps={{ style: { display: 'none' } }}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs='auto'>
                <Pagination
                  count={paginator.totalPages}
                  defaultPage={paginator.pageNumber}
                  onChange={handleChangePagination}
                  color='primary'
                />
              </Grid>
            </Grid>
          </Grid>
        </Panel>
      </Content>

      {employeeToChangeStatus && (
        <ModalV2.TwoOptions
          loading={changingEmployeeStatus}
          open={employeeToChangeStatus}
          onClose={() => setEmployeeToChangeStatus(false)}
          leftText={isEmployeeActive ? 'Manter Ativo' : 'Manter Inativo'}
          rightText={isEmployeeActive ? 'Inativar' : 'Ativar'}
          onLeftClick={() => setEmployeeToChangeStatus(false)}
          onRightClick={() => {
            changeEmployeeStatus(employeeToChangeStatus.employeeId)
          }}
        >
          Esse colaborador está {isEmployeeActive ? 'ativo' : 'inativo'}. Tem certeza que
          <br />
          deseja {isEmployeeActive ? 'inativar' : 'ativar'}?
        </ModalV2.TwoOptions>
      )}
      {isHasOrderModalVisible && (
        <ModalV2.OneOption
          open={isHasOrderModalVisible}
          onClose={() => setHasOrderModalVisible(false)}
          onOptionClick={() => setHasOrderModalVisible(false)}
        >
          <HasOderText>
            <IoIosInformationCircleOutline />
            <p>Você não pode inativar esse colaborador.</p>
            <br />
            <p>
              Existe um pedido de recarga para o cartão associado ao colaborador.
              <br />
              Pague ou cancele o pedido e tente novamente.
            </p>
          </HasOderText>
        </ModalV2.OneOption>
      )}
      <Filter
        open={isFilterOpen}
        isLoading={fetchFilterValuesState.loading}
        onClose={() => setIsFilterOpen(false)}
        allowEmptyFilter
        onFilter={(value) => {
          const filterValues = Object.keys(value).reduce((final, actual) => {
            const newFinal = { ...final }
            if (value[actual].length !== 0 && value[actual] !== '') newFinal[actual] = value[actual]
            return newFinal
          }, {})
          const newQuery = { ...paginator.query }
          Object.keys(value).forEach((key) => {
            if (!filterValues[key] && filterValues[key] !== 0) delete newQuery[key]
            else newQuery[key] = filterValues[key]
          })
          setPaginator(paginatorMethods.overrideFilters(paginator, { ...newQuery }))
          setIsFilterOpen(false)
        }}
        fields={[
          {
            type: 'multiSelect',
            name: 'benefitType',
            props: {
              label: 'Benefícios',
              fullWidth: true,
              variant: 'contained',
              options: filterData.benefitTypes,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            },
          },
          {
            type: 'multiSelect',
            name: 'benefitId',
            props: {
              label: 'Nome Cartão',
              fullWidth: true,
              variant: 'contained',
              options: filterData.operatorsCards,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            },
          },
          {
            type: 'multiSelect',
            name: 'departmentId',
            props: {
              label: 'Departamento',
              fullWidth: true,
              variant: 'contained',
              options: filterData.departments,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            },
          },
          {
            type: 'select',
            name: 'status',
            initialValue: 1,
            props: {
              label: 'Status',
              fullWidth: true,
              options: [
                { value: EMPLOYEE_STATUS.ACTIVE, label: 'Ativo' },
                { value: EMPLOYEE_STATUS.INACTIVE, label: 'Inativo' },
              ],
            },
          },
          {
            type: 'select',
            name: 'integrationsStatus',
            props: {
              label: 'Status de Integração',
              fullWidth: true,
              isClearable: true,
              options: [
                { value: EMPLOYEE_OPERATOR_INTEGRATION.OK, label: 'Sincronizado' },
                { value: EMPLOYEE_OPERATOR_INTEGRATION.RUNNING, label: 'Aguardando sincronismo' },
                { value: EMPLOYEE_OPERATOR_INTEGRATION.ERROR, label: 'Erro de sincronismo' },
              ],
            },
          },
        ]}
      />
      {open && <AssignDaysModal open={open} onClose={handleCloseModal} />}
    </>
  )
}

export default Employees
