import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Theme } from '@mui/material'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { Box, Form, formatMoney, FormSelect, Grid, Icon, Skeleton, Typography } from '@stationkim/front-ui'
import { NewOrderContainer } from '../styles'
import { IUseBrokerOrderReturn, LayoutOption } from '../hooks/shared'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

interface RepeatImportProps {
  form: UseFormReturn<any>
  isLoading: IUseBrokerOrderReturn['isLoading']
  operator: LayoutOption
  options: LayoutOption[]
  order: IUseBrokerOrderReturn['lastOrder']
  setOperatorId: IUseBrokerOrderReturn['setOperatorId']
}

export const RepeatImport = ({ form, isLoading, operator, options, order, setOperatorId }: RepeatImportProps) => {
  const operatorId = form.watch('operatorId')
  const account = useAtomValue(AccountPersistentStore)

  useEffect(() => {
    if (setOperatorId) setOperatorId(options.find((option) => option.id === operatorId)?.operatorId)
  }, [operatorId, setOperatorId, options])

  const renderSelect = () => {
    if (!account.company.newBroker) return null
    if (isLoading.options)
      return (
        <Grid item xs={12} sm={6} sx={{ marginTop: '32px' }}>
          <Skeleton variant='rounded' height='48px' width='100%' fullWidth />
        </Grid>
      )
    else
      return (
        <FormSelect
          variant='outlined'
          label='Selecione o modelo do pedido que deseja repetir'
          required
          placeholder='Selecione...'
          name='operatorId'
          options={options}
          getOptionValue={(option) => option.id}
          rules={{ required: 'É preciso selecionar uma operadora' }}
          gridProps={{ xs: 12, sm: 'auto' }}
          disabled={isLoading.lastOrder}
        />
      )
  }

  return (
    <NewOrderContainer>
      <Form id='orderImportForm' form={form} onSubmit={() => {}} gridProps={{ spacing: '16px' }}>
        {renderSelect()}

        <Grid item xs={12} sx={{ marginTop: '16px', filter: order ? 'none' : 'grayscale(100)' }}>
          {isLoading.lastOrder ? (
            <Skeleton variant='rounded' height='66px' width='100%' />
          ) : (
            <a href={order?.detailsLink} target='_blank' rel='noreferrer' style={{ all: 'unset', cursor: 'pointer' }}>
              <Box
                sx={(theme) => ({
                  background: !order ? 'none' : theme.palette.primary.light + '0D',
                  opacity: !order ? 0.6 : 1,
                  border: '1px solid ' + theme.palette.primary.light,
                  padding: '8px 16px',
                  borderRadius: '4px',
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'center',
                  height: '66px',
                })}
              >
                {!order ? (
                  <Typography sx={{ margin: 'auto' }}>
                    {!operator ? 'Selecione uma operadora' : 'Nenhum pedido encontrado para essa operadora'}
                  </Typography>
                ) : (
                  <>
                    <Icon
                      icon={faFileUpload}
                      sx={(theme: Theme) => ({
                        background: theme.palette.primary.light + '1D',
                        color: theme.palette.primary.main,
                        padding: '8px',
                        height: '24px',
                        width: '24px',
                        borderRadius: '50%',
                      })}
                    />
                    <Box>
                      <Typography
                        sx={() => ({
                          fontWeight: 500,
                        })}
                      >
                        #{order?.code}
                      </Typography>
                      {account.company.newBroker && (
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.grey[500],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            '& div': {
                              height: '16px',
                            },
                          })}
                        >
                          {order?.orderDate}
                          <div>º</div>
                          {order?.itemsInOrder ?? '?'} {order.itemsInOrder > 1 ? 'colaboradores' : 'colaborador'}
                          <div>º</div>
                          R$ {typeof order?.totalValue === 'number' ? formatMoney(order?.totalValue) : '??,??'}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </a>
          )}
        </Grid>
      </Form>
    </NewOrderContainer>
  )
}
