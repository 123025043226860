import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '@mui/material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Typography } from '@stationkim/front-ui'

interface ConfirmEditionCancelDialogProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const ConfirmEditionCancelDialog = ({ onCancel, onConfirm, open }: ConfirmEditionCancelDialogProps) => {
  return (
    <Dialog open={open} sx={{ textAlign: 'center' }}>
      <Icon
        icon={faWarning}
        sx={(theme: Theme) => ({
          color: 'warning.main',
          height: '64px',
          width: '64px',
          margin: 'auto',
          marginBottom: '16px',
          backgroundColor: theme.palette.grey[50],
          padding: '16px',
          borderRadius: '50%',
        })}
      />
      <DialogTitle sx={{ margin: 'auto' }}>Cancelar edição do Colaborador?</DialogTitle>
      <DialogContent sx={{ margin: '24px 0 32px 0' }}>
        <Typography>
          Tem certeza que deseja cancelar a edição desse colaborador? Todas as alterações feitas serão descartadas.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} fullWidth sx={{ filter: 'grayscale(100)' }}>
          Não
        </Button>
        <Button variant='contained' onClick={onConfirm} fullWidth color='warning'>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}
