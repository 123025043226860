import { formatMoney } from '@stationkim/front-ui'
import { BENEFIT_TYPES, EMPLOYEE_BENEFIT_STATUS } from '@/main/utils/enumsMapping'
import { ActionMenu, ActionMenuProps } from '../components/actionMenu'
import { Status } from '../styles'

export const columnsConfig = ({ onEdit, onStatusChange }: Pick<ActionMenuProps, 'onEdit' | 'onStatusChange'>) => {
  return [
    {
      columnDisplayName: 'Número',
      columnValue: ({ row }) => row.cardNumber,
    },
    {
      columnDisplayName: 'Nome',
      columnValue: ({ row }) => row.cardName,
    },
    {
      columnDisplayName: 'Benefício',
      columnValue: ({ row }) => BENEFIT_TYPES[row.benefitType],
    },
    {
      columnDisplayName: 'Valor Diário',
      columnValue: ({ row }) => 'R$ ' + formatMoney(row.dailyRate),
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => <Status benefitStatus={row.status}>{EMPLOYEE_BENEFIT_STATUS[row.status]}</Status>,
    },
    {
      columnDisplayName: 'Ações',
      columnValue: ({ row, index }) => (
        <ActionMenu id={`employeeBenefit_row_${index}`} benefit={row} onEdit={onEdit} onStatusChange={onStatusChange} />
      ),
    },
  ]
}
