import api from '@/infra/api'
import { EEmployeeBenefitStatus, EBenefitType } from '@/main/enums'
import { IFetchHook } from '@/main/interfaces'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

type TApiBenefit = {
  benefitId: string
  cardName: string
  cardId: null | string
  cardNumber: null | string
  dailyRate: number
  benefitType: EBenefitType
  status: EEmployeeBenefitStatus
}

interface IApiResponse {
  data: TApiBenefit[]
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

export type TBenefit = TApiBenefit

interface UseEmployeeBenefitsReturn {
  benefits: {
    data: TBenefit[]
    pageNumber: number
    pageSize: number
    totalItens: number
    totalPages: number
  }
  isLoading: boolean
}

interface UseEmployeeBenefitsProps extends Omit<IFetchHook, 'filter'> {
  employeeId: string
}

export const useEmployeeBenefits = ({
  pagination,
  employeeId,
}: UseEmployeeBenefitsProps): UseEmployeeBenefitsReturn => {
  const { data, isFetching } = useQuery<AxiosResponse<IApiResponse>>({
    queryKey: ['employeeBenefits', pagination, employeeId],
    queryFn: () => api.get('rh-bff/Benefit/employees/cards/paged', { params: { ...pagination, employeeId } }),
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(employeeId),
  })

  const benefits: UseEmployeeBenefitsReturn['benefits'] = useMemo(() => {
    const rawData = data?.data
    if (!rawData)
      return {
        data: [],
        pageNumber: 0,
        pageSize: 0,
        totalItens: 0,
        totalPages: 0,
      }
    return rawData
  }, [data])

  return {
    benefits: benefits,
    isLoading: isFetching,
  }
}
